<template>
  <Line v-if="loaded" ref="statisticHistory" class="my-10" :data="data" :options="options" />
</template>

<script>

import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { Line } from 'vue-chartjs';


ChartJS.register(
    TimeScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)
export default {
  name: "StatisticChart",
  components: {
    Line
  },
  props:{
    history:Array
  },
  computed:{
    userData(){
      let result=[];
      for(let dataset of this.history){
        result.push({
          x:dataset.timestamp,
          y:dataset.numberOfUsers
        })
      }
      return result;
    },
    objectData(){
      let result=[];
      for(let dataset of this.history){
        result.push({
          x:dataset.timestamp,
          y:dataset.numberOfSuccessObjects
        })
      }
      return result;
    },
    pendingObjectData(){
      let result=[];
      for(let dataset of this.history){
        result.push({
          x:dataset.timestamp,
          y:dataset.numberOfPendingObjects
        })
      }
      return result;
    }
  },
  mounted() {
    console.log(this.userData);
    this.data.datasets[0].data=this.userData;
    this.data.datasets[1].data=this.pendingObjectData;
    this.data.datasets[2].data=this.objectData;
    this.loaded=true;
  },
  data(){
    return{
      loaded:false,
      options : {
        fill:true,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },

        plugins: {
          legend: {
            display: false
          },
        },

        scales: {

          x: {
            type: 'time',
            display:true,
            grid:{
              display:true
            }

          },
          y: {
            grid:{
              display:false
            },
            display:false
          }
        },
      },
      data :{
        labels:[],
        datasets: [
          {
            label: 'Users',
            backgroundColor: '#3D4C624D',
            borderWidth:2,
            borderColor:'#3D4C62',
            tension:0.6,
            data: [
            ]
          },
          {
            label: 'Pending Objects',
            backgroundColor: '#F17AF54D',
            borderWidth:2,
            borderColor:'#F17AF5',
            tension:0.6,
            data: [
            ]
          },
          {
            label: 'Sold Objects',
            backgroundColor: '#EBF57A4D',
            borderWidth:2,
            borderColor:'#EBF57A',
            tension:0.6,
            data: [
            ]
          },
        ]
      },
    }
  }
}
</script>

<style scoped>

</style>