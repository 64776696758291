<template>
<v-list>

  <v-list-item v-for="downloadable of downloadables" :key="downloadable.id">
    <template #title>
      <v-tooltip location="bottom">
      <template v-slot:activator="{ props }">
        <span v-bind="props" v-text="downloadable.filename"></span>
      </template>
        <div>{{downloadable.filename}}</div>
      </v-tooltip>
    </template>
    <template #append>
      <v-btn variant="plain" icon="mdi-download" @click="download(downloadable)"></v-btn>
    </template>
  </v-list-item>

</v-list>
</template>

<script>
import {downloadFileFromBase64, getAllDownloadablesByType} from "@/services/file.service";

export default {
  name: "DownloadableList",
  props:{
    type:String
  },
  data(){
    return{
      downloadables:null
    }
  },
  created() {
    this.getDownloadables();
  },
  methods:{
    async download(file){
      downloadFileFromBase64(file);
    },
    async getDownloadables() {
      if (!this.type) return [];
      this.downloadables = await getAllDownloadablesByType(this.type)
    }
  }
}
</script>

<style scoped>

</style>