import ApiService from '@/services/api.service';
export function downloadFileFromBase64(file) {

    let base64String=file.file;
    let fileName=file.filename;
    let fileType=file.contentType;
    if(!fileType) fileType="text/plain";


    // Create a link element
    const link = document.createElement('a');
    link.href = "data:"+fileType+";base64," + base64String;
    link.download = fileName;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();
    setTimeout(()=>{

        document.body.removeChild(link);
    },5000);

    // Clean up

}export function downloadFileFromBase64CompleteString(base64String) {

    // Create a link element
    const link = document.createElement('a');
    link.href = base64String;
    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.dispatchEvent(new MouseEvent('click'));

    // Clean up
    document.body.removeChild(link);
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export async function getDownloadable(id) {
    const response = await ApiService.get("downloadable/get/" + id);
    return response.data;
}

export async function getAllDownloadablesByType(type){
    const response = await ApiService.get("downloadable/get/"+type+"/all");
    return response.data;
}

