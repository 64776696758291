<template>
  <v-skeleton-loader type="list-item-avatar-two-line@5" color="transparent" :loading="!section">
  <v-list v-if="section.courses.length>0" class="px-2" bg-color="transparent" >
   <!-- <draggable ghost-class="ghost" class="dragArea" :list="section.courses" @change="log">
      <transition-group name="list">-->
  <AdminCourseItem v-for="course of section.courses" :key="course.id" :course="course" :section-id="sectionId"></AdminCourseItem>
     <!-- </transition-group>
    </draggable>-->
  </v-list>
  <v-list width="100%" v-else>
    <v-list-item title="Diese Sektion hat keine Kurse"/>
  </v-list>
  </v-skeleton-loader>
  <v-btn class="my-2" v-if="changedOrder" color="success" block >Reihenfolge speichern</v-btn>
  <v-btn class="my-2" block @click="$router.push('/admin/weiterbildung/section/'+section.id+'/course/create')">Neuen Kurs erstellen</v-btn>

</template>

<script>
import {getSectionById} from "@/services/courses.service";
import AdminCourseItem from "@/components/admin/courses/AdminCourseItem";
//import { VueDraggableNext } from 'vue-draggable-next'
export default {
  name: "AdminCourses",
  components: {AdminCourseItem,
    //draggable: VueDraggableNext,
    },
  props:{
    sectionId:Number
  },
  data(){
    return{
      section:null,
      changedOrder:false
    }
  },
  created(){
    this.getSection(this.sectionId);
  },
  methods:{
    async getSection(sectionId) {
      this.section = await getSectionById(sectionId);
    },
    log(event) {
      this.changedOrder=true;
      console.log(event);
      let index=1;
      for(let course of this.section.courses){
        course.chapter=index;
        index++;
      }
    },
  }
}

//TODO Editieren mit Bottom Sheet -> Fragen hinzufügen & Details bearbeiten, löschen
//TODO Beim löschen muss gecheckt werden, ob jemand den Kurs gerade macht. Was passiert dann?
</script>

<style scoped>
.ghost{
 opacity: 0.6;
}
</style>