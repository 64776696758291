<script>
import ApiService from "@/services/api.service";
import {debounce} from "lodash";

export default {
  name: "PaginationWrapper",
  data() {
    return {
      items: [],
      page: 1,
      totalItems: 0,
      loading: false,
      query: "",
      debouncedLoadPage: null
    };
  },
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    url: {
      type: String,
      required: true
    }
  },
  methods:{
  async loadPage(page = 1) {
    this.loading = true;
    if (!page) page = 1;
    try {
      console.log("Loading page", this.url + (page - 1));
      const response = await ApiService.post(this.url + (page - 1), {string: this.query});
      const data = response.data;
      this.items = data.content;
      this.totalItems = data.totalElements;
      this.page = data.number + 1;
    } catch (error) {
      console.error("Failed to load users:", error);
    } finally {
      this.loading = false;
    }
  },
  onPageChange(newPage) {
    this.loadPage(newPage);
  }
},
  created() {
    this.debouncedLoadPage = debounce((page) => this.loadPage(page), 300);
    this.loadPage();
  },
}
</script>

<template>
  <slot name="filter">
    <v-text-field append-inner-icon="mdi-magnify" v-model="query" label="Search" @keydown="debouncedLoadPage(1)" />
  </slot>
  <slot :items="items">
    <v-row>
      <p v-if="items.length<=0">Keine Objekte gefunden</p>
      <v-col v-for="item of items" :key="item.id" cols="12" md="6">

        <slot :item="item" name="item"/>
      </v-col>
    </v-row>
  </slot>
  <v-pagination
      v-model="page"
      :length="Math.ceil(totalItems / pageSize)"
      @update:modelValue="onPageChange"
  ></v-pagination>
</template>

<style scoped>

</style>