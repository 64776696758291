<template>
  <v-row class="my-2" align="center">
    <v-col cols="8">

      <v-slider
          :disabled="disabledP"
          class="text-white"
          :label="label"
          hide-details
          :max="maxValue"
          step="1"
          v-model="valueReactive"
      ></v-slider>
    </v-col>
    <v-col cols="4">
      <v-text-field :disabled="disabledP"  class="text-white small-input" hide-details density="compact" type="number" v-model="valueReactive">
        <template #append-inner>
          m²
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {useObjectStore} from "@/stores/objects.module";

export default {
  name: "SquareMeterSlider",
  setup(){
    const objectStore = useObjectStore();

    return {
      objectStore
    }
  },
  props:{
    disabledP:Boolean,
    value:{
      default:0,
      type:Number
    },
    label:String,
    maxValue:Number
  },
  computed:{
    valueReactive:{
      get(){
        return this.value;
      },set(value){
        this.$emit("update",value);
      }
    }
  }
}
</script>

<style scoped>

</style>