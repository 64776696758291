<template>
  <v-dialog v-model="show">
    <template #activator="{props}">
      <StepListItem v-bind="props" :disabled="disabled" title="Coins einsammeln" :status="state.state" message="" />
    </template>
    <v-card v-if="state.state!='success'">
      <v-card-text>
        Du kannst deine Coins nun einsammeln! Außerdem kannst du dir jetzt auch die Coins in Euro auf dein angegebenes Konto auszahlen lassen.
      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" @click="collect">Coins einsammeln</v-btn>
        <v-spacer></v-spacer>
        <v-btn type="text" @click="show=false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text>Du hast deine Coins bereits eingesammelt.</v-card-text>
      <v-card-actions>
        <v-btn type="text" @click="show=false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Create Security Mechanism that every transaction gets paid and only one time-->
  <!--Give User XP give it directly on notartermin-->
  <!--In Progressbar von footer Coin symbol & Coins einsammeln-->
</template>

<script>

import StepListItem from "@/components/objects/progress/StepListItem";
import {collectCoins} from "@/services/collect-coins.service";
export default {
  name: "CollectCoinsStep",
  components: {StepListItem},
  props:{
    requirements:Array,
    objectId:String,
    state:Object,
  },
  data(){
    return{
      show:false,
    }
  },
  computed:{
    disabled(){

      for(let requirement of this.requirements){
        if(!requirement.value) return true;
      }
      return false;
    }
  },
  methods: {
    async collect() {
      let transaction = await collectCoins(this.objectId);
      console.log("Coins collected", transaction);
      this.$router.push("/wallet");
    }
  }
}
</script>

<style scoped>

</style>