<template>
  <v-list-subheader class="mt-2">Eigentümer</v-list-subheader>
  <v-list-item align="left" lines="three" :title="owner.fullName">
    <template #subtitle>
      <a variant="text" v-html="owner.email"></a><br/>
      <a varient="text" v-html="owner.telephone"></a>
    </template>
    <template #append>
      <v-btn icon variant="text"><v-icon icon="mdi-phone"></v-icon></v-btn>
      <v-btn icon variant="text"><v-icon icon="mdi-mail"></v-icon></v-btn>
    </template>

  </v-list-item>
</template>

<script>
export default {
  name: "OwnerInformation",
  props:{
    owner:Object
  }
}
</script>

<style scoped>

</style>