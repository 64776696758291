// Pinia Store
import { defineStore } from 'pinia';
import ApiService from '@/services/api.service';

const state = () => ({
        server:true,
});
export const useStatusStore = defineStore('statusStore',{
    state: state,



// getters
    getters: {

    },

// actions
    actions: {
        async checkServerState() {
            this.server = await ApiService.checkServerState();
            console.log("Server Status",this.server);
        },
    }
});