<template>
  <v-container>
<v-list align="left">
<VerificationItem v-for="verification of verifications" :key="verification.objectId" :verification="verification"/>
</v-list>
  </v-container>
</template>

<script>
import {useAdminStore} from "@/stores/admin.module";
import VerificationItem from "@/components/admin/VerificationItem";

export default {
  name: "VerificationList",
  components: {VerificationItem},
  setup(){
    const adminStore = useAdminStore();

    adminStore.getPendingVerifications();
    adminStore.getPendingEstateObjects();

    return{
      adminStore
    }
  },
  computed:{
    verifications(){
      return this.adminStore.pendingVerifications;
    }
  }
}
</script>

<style scoped>

</style>