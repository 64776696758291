export default class CreateCourse{
    id;
    title;
    description;
    presentation;
    video;
    seal;
    sectionId;
    chapter;

    constructor({id,title,description,presentation,video,seal,sectionId,chapter}){
        this.id = id;
        this.title = title;
        this.description = description;
        this.presentation = presentation;
        this.video = video;
        this.seal = seal;
        this.sectionId = sectionId;
        this.chapter = chapter;



    }
}