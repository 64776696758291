<template>
<v-container>
  <v-tabs v-model="tab">
    <v-tab value="userView">
      Useransicht
    </v-tab>
    <v-tab value="overview">
      Überblick
    </v-tab>
    <v-tab value="maklervertrag">
      Maklervertrag
    </v-tab>
    <v-tab value="vermarktung">
      Vermarktung
    </v-tab>
    <v-tab value="zahlung">
      Zahlung
    </v-tab>
  </v-tabs>
  <div class="px-5 pt-10">
  <div class="text-center mb-5 "><span v-html="objectName"></span></div>
  <ValueOverviewAdmin @update="updateSaleObject" v-if="sale" :sale="sale"/>
  <v-row>
    <v-col cols="4">
      <p v-html="marktwert"></p>
      <small>Marktwert</small>
    </v-col>
    <v-col cols="4">
      <p v-html="sale?.provision+'%'"></p>
      <small>Provision</small>
    </v-col>
    <v-col cols="4">
      <p v-html="sale?.tipsterProvision+'%'"></p>
      <small>Tippgeber</small>
    </v-col>
  </v-row>
  </div>
  <v-window v-model="tab">
    <v-window-item value="userView">
      <ObjectView :id="objectId"/>
    </v-window-item>
    <v-window-item class="px-5 py-10" value="overview">


  <div>
    <v-list bg-color="transparent">
      <RequirementsItem v-for="(requirement,$index) of requirements" :key="$index" :requirement="requirement"/>
    </v-list>
  </div>
      <ObjectInfo v-if="estateObject" :object="estateObject"/>
    </v-window-item>
    <v-window-item class="px-5 py-10" value="maklervertrag">
      <RequirementsItem :requirement="requirements.contractIsPresent"/>
      <RequirementsItem :requirement="requirements.contractSigned"/>
      <EditMaklerVertrag :object-id="estateObject.id"/>
    </v-window-item>
    <v-window-item class="px-5 py-10" value="vermarktung">
      <RequirementsItem :requirement="requirements.notaryAppointmentSuccess"/>
      <EditNotarTermin :object-id="estateObject.id"/>
    </v-window-item>
    <v-window-item class="px-5 py-10" value="zahlung">
      <RequirementsItem :requirement="requirements.paymentReceived"/>
      <EditVerkauf :sale="sale" @update="updateSaleObject"/>
    </v-window-item>
  </v-window>

</v-container>
</template>

<script>
import RequirementsItem from "@/components/RequirementsItem";
import {useObjectStore} from "@/stores/objects.module";
import {getEstateObject, getObjectName, getSale} from "@/services/object.service";
import ObjectInfo from "@/components/admin/object/ObjectInfo";
import EditMaklerVertrag from "@/components/admin/object/EditMaklerVertrag";
import EditNotarTermin from "@/components/admin/object/EditNotarTermin";
import EditVerkauf from "@/components/admin/object/EditVerkauf";
import ValueOverviewAdmin from "@/components/admin/object/ValueOverviewAdmin";
import ObjectView from "@/views/ObjectView";

export default {
  name: "EditObjectAdmin",
  components: {
    ObjectView,
    ValueOverviewAdmin, EditVerkauf, EditNotarTermin, EditMaklerVertrag, ObjectInfo, RequirementsItem},
  setup(){
    const objectStore = useObjectStore();



    return{
      objectStore
    }
  },
  props:{
    objectId:String
  },
  data(){
    return{
      tab:'overview',
      sale:null,
      estateObject:null
    }
  },
  created() {
    this.getEstateObject();
    this.objectStore.updateRequirements(this.objectId);
    this.getSale();
  },

  methods:{
    updateSaleObject(object){
      this.sale = object;
    },
    async getSale() {
      this.sale = await getSale(this.objectId);
    },
    async getEstateObject(){
      this.estateObject=await getEstateObject(this.objectId);
    }
  },
  computed:{
    requirements(){
      return this.objectStore.requirements;
    },
    objectName(){
      if(this.estateObject) return getObjectName(this.estateObject);
      else return null;
    },
    marktwert(){
      if(!this.sale) return null;
      else if(this.sale.actualValue) return this.sale.actualValue;
      else return this.sale.value;
    }
  }
}
</script>

<style scoped>

</style>