import Compressor from "compressorjs";

export function blobToBase64(blob){
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
}
export async function compressBase64String(base64String) {
    const blob= dataURItoBlob(base64String)
    console.log(blob);
    const compressedBlob = await compressImage(blob);
    console.log(compressedBlob);
    return await blobToBase64(compressedBlob);


}

export function dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
}
export function compressImage(file){
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.1,
            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success: resolve,
            error: reject,
        });
    }).then((result) => {
        console.log('Compress success',result);
        return result;
    }).catch((err) => {
        console.log('Compress error');
        window.alert(err.message);
    }).finally(() => {
        console.log('Compress complete');
    });
}
export function base64Size(base64String) {

    let stringLength = base64String.length - 'data:image/png;base64,'.length;

    let sizeInBytes = 3 * Math.ceil((stringLength / 4));

    if (base64String.length >= 2) {
        let lastTwo=base64String.slice(-2);
        var paddings = lastTwo;

        sizeInBytes = paddings==="==" ? sizeInBytes - 2 : paddings[1]==='=' ? sizeInBytes - 1 : sizeInBytes;
    }
    let sizeInKb = sizeInBytes / 1024;
    return Math.round((sizeInKb + Number.EPSILON) * 100) / 100;
}

export async function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {

            let base64String=fileReader.result;
            base64String=base64String.replace("data:","");
            base64String=base64String.split(";")[1];
            base64String=base64String.split(",")[1];
            resolve(base64String);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}