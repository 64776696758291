<template>
<v-list-item align="left" v-if="requirement.label" :title="requirement.label">
  <template #prepend>
    <v-icon v-if="requirement.value" color="success" icon="mdi-check"></v-icon>
    <v-icon v-else icon="mdi-close"></v-icon>
  </template>

</v-list-item>
</template>

<script>
export default {
  name: "RequirementsItem",
  props:{
    requirement:Object
  }
}
</script>

<style scoped>

</style>