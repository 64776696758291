<template>
  <v-dialog v-model="showReactive">
    <template #activator="{props}">
      <StepListItem v-bind="props" :disabled="disabledC" title="Objektdaten einpflegen" :message="state.message" :message-color="state.color" :status="state.state" />
    </template>
    <v-card class="py-2 px-3">
    <ObjectData @updateObject="updateObject" :object="object"/>
      <v-card-actions>
        <v-btn variant="text" @click="showReactive=false">Schließen</v-btn><v-spacer></v-spacer><v-btn @click="save">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StepListItem from "@/components/objects/progress/StepListItem";
import ObjectData from "@/components/objects/ObjectData";
import {updateEstateObject} from "@/services/object.service";
import {useObjectStore} from "@/stores/objects.module";
export default {
  name: "ObjectDataStep",
  setup(){
    const objectState = useObjectStore();
    return{
      objectState
    }
  },
  components:{
    ObjectData,
    StepListItem
  },
  props:{
    show:Boolean,
    requirements:Array,
    object:Object,
    statusRequirements:Object,
    state:Object
  },
  methods:{
    async save(){
      await updateEstateObject(this.objectReactive);
      this.showReactive=false;
      this.objectState.updateRequirements(this.object.id);
      this.objectState.updateStates(this.object.id);
    },
    updateObject(value) {
      this.objectReactive = value;
    }
  },
  computed:{
    showReactive:{
      get(){
        return this.show;
      },set(value){
        this.$emit('toggleDialog',value)
      }
    },
    objectReactive:{
      get(){
        return this.object;
      },set(value){
        this.$emit("update",value);
      }
    },
    disabledC(){
      for(let requirement of this.requirements){
        if(!requirement.value) return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>