<template>
<v-row>
<v-col v-for="object of objects" :key="object.id" cols="12">
  <MarketingItem :estate-object="object"/>
</v-col>
</v-row>
</template>

<script>
import MarketingItem from "@/components/admin/vermarktung/MarketingItem";

export default {
  name: "MarketingList",
  props:{
    objects:Array
  },
  components: {MarketingItem},
}
</script>

<style scoped>

</style>