<template>

  <svg v-if="!multiple" :width="size" :class="{'ml-1':end,'mr-1':start}"  viewBox="0 0 842 842" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M794.179 390.067L449.136 47.8075C432.668 31.472 406.075 31.5797 389.739 48.048L47.4799 393.091C31.1445 409.559 31.2521 436.152 47.7204 452.487L392.763 794.747C409.231 811.082 435.824 810.974 452.16 794.506L794.419 449.463C810.755 432.995 810.647 406.402 794.179 390.067Z" fill="black" stroke="url(#paint0_diamond_287_10)" stroke-width="70"/>
    <path d="M563 413.307L434.27 280.199C430.808 277.267 425.192 277.267 421.73 280.199L295 413.307" stroke="white" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M563 520.614L434.27 387.506C430.808 384.574 425.192 384.574 421.73 387.506L295 520.614" stroke="white" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <radialGradient id="paint0_diamond_287_10" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(421 114) rotate(90) scale(651.5)">
        <stop offset="0.565972" stop-color="#A96F9D"/>
        <stop offset="1" stop-color="#CCA876"/>
      </radialGradient>
    </defs>
  </svg>
  <svg v-else :width="size" :class="{'ml-1':end,'mr-1':start}" viewBox="0 0 948 882" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M899.78 390.34L554.737 48.0809C538.269 31.7454 511.676 31.8531 495.34 48.3214L153.081 393.364C136.746 409.832 136.853 436.425 153.322 452.761L498.364 795.02C514.832 811.356 541.425 811.248 557.761 794.78L900.02 449.737C916.356 433.269 916.248 406.676 899.78 390.34Z" fill="black" stroke="url(#paint0_diamond_300_28)" stroke-width="70"/>
    <path d="M668.602 413.581L539.872 280.472C536.409 277.541 530.794 277.541 527.332 280.472L400.602 413.581" stroke="white" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M668.602 520.888L539.872 387.779C536.409 384.848 530.794 384.848 527.332 387.779L400.602 520.888" stroke="white" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
    <g filter="url(#filter0_d_300_28)">
      <path d="M798.78 421.34L453.737 79.0809C437.269 62.7454 410.676 62.8531 394.34 79.3214L52.081 424.364C35.7455 440.832 35.8532 467.425 52.3215 483.761L397.364 826.02C413.832 842.356 440.425 842.248 456.761 825.78L799.02 480.737C815.356 464.269 815.248 437.676 798.78 421.34Z" fill="black" stroke="url(#paint1_diamond_300_28)" stroke-width="70"/>
      <path d="M567.602 444.581L438.872 311.472C435.409 308.541 429.794 308.541 426.332 311.472L299.602 444.581" stroke="white" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M567.602 551.888L438.872 418.779C435.409 415.848 429.794 415.848 426.332 418.779L299.602 551.888" stroke="white" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_d_300_28" x="0.899414" y="31.8994" width="849.302" height="849.302" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_28"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_28" result="shape"/>
      </filter>
      <radialGradient id="paint0_diamond_300_28" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(526.601 114.273) rotate(90) scale(651.5)">
        <stop offset="0.565972" stop-color="#A96F9D"/>
        <stop offset="1" stop-color="#CCA876"/>
      </radialGradient>
      <radialGradient id="paint1_diamond_300_28" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(425.601 145.273) rotate(90) scale(651.5)">
        <stop offset="0.565972" stop-color="#A96F9D"/>
        <stop offset="1" stop-color="#CCA876"/>
      </radialGradient>
    </defs>
  </svg>



</template>

<script>
export default {
  name: "TokenIcon",
  props:{
    size:{
      type:Number,
      default:15
    },
    multiple:Boolean,
    end:Boolean,
    start:Boolean
  }
}
</script>

<style scoped>

</style>