<template>
  <div style="height:100%; width:100%">
    <l-map style="pointer-events: none" class="hide-zoom" ref="readonlyMap" :zoom="17" :center="center">
      <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
      ></l-tile-layer>
      <l-marker :lat-lng="markerLocation"></l-marker>
    </l-map>
  </div>
</template>

<script>

import "leaflet/dist/leaflet.css";
import {LMarker, LMap, LTileLayer} from "@vue-leaflet/vue-leaflet";

export default {
  name: "MapDisplayOnly",
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props:{
    markerLocation:Array,
  },
  data() {
    return {
      zoom: 17,
      center:this.markerLocation
    };
  }
}
</script>

<style scoped>

</style>