<template>
<v-sheet>
  <p v-html="response.message"></p>
</v-sheet>
</template>

<script>
import {confirmToken} from "@/services/confirmation-token.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";

export default {
  name: "ConfirmToken",
  setup(){
    const authStore= useAuthUserStore();
    return{authStore};
  },
  props:{
    token:String
  },
  data(){
    return{
      loading:false,
      response:{
        status:0,
        message:null
      }
    }
  },
  created(){
   this.confirm();
  },
  methods:{
    updateUser(){
      this.authStore.updateUser();
    },
    async confirm() {
      this.loading = true;
      if (this.token){
        this.response = await confirmToken(this.token);
        if(this.response.status===1) {
          const authStore = useAuthUserStore();
          authStore.verifyEmail();
        }
        this.loading=false;
      }
      else {
        this.loading = false;
        this.response = {
          status: 0,
          message: "Kein Token"
        }
      }
    }
  }
}
</script>

<style scoped>

</style>