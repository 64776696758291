// Pinia Store
import { defineStore } from 'pinia'
import {getRanking} from "@/services/ranking.service";
import UserService from "@/services/user.service";
import {getUsersAchievements} from "@/services/achievement.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";

const state = () => ({
    all:[],
    ranked:[],
    rankedPage:0,
    currentUser:{
        addresses:[],
        freeSlots:0
    },
    achievements:[]
});
export const useUserStore = defineStore('userStore',{
    state: state,



// getters
    getters: {
    },

// actions
    actions: {
        initRankedUsers() {
            getRanking(0).then(response => {

                this.ranked = response;
            });
        },
        loadMoreRankedUsers() {
            this.rankedPage++;
            getRanking(this.rankedPage).then(response => {
                this.ranked.push(...response);
            });
        },
        getAllUsers() {
            return this.all = [
                {
                    name: "David Zimmert",
                    points: 8760,
                    role: "Partner"
                },
                {
                    name: "Max Mustermann",
                    points: 8761,
                    role: "Partner"
                },
                {
                    name: "Jonah Runge",
                    points: 6777,
                    role: "Lulli"
                },
                {
                    name: "Mari Heilmeier",
                    points: 8762,
                    role: "Partner"
                }
            ]
        },
        loadAddresses() {
            let self = this;
            UserService.getAddresses().then(response => {
                self.currentUser.addresses = response;
            });
        },
        saveAddress(address) {
            let self = this;
            UserService.saveAddress(address).then(response => {
                let index = self.currentUser.addresses.findIndex(x => x.id === response.id);
                if (index != -1) self.currentUser.addresses[index] = response;
                else self.currentUser.addresses.push(response);
            });
        },
        async getMyAchievements() {
            const authStore = useAuthUserStore();
            this.achievements = await getUsersAchievements(authStore.user.id);

        },
        async buySlotWithToken(){
            await UserService.buySlotWithToken();
            await this.getMyFreeSlots();
        },
        async getMyFreeSlots(){
            this.currentUser.freeSlots= await UserService.getFreeSlots();
        }

    }
});