<template>
<v-list style="background-color: transparent;" color="transparent">
  <v-list-item v-if="!questions || questions.length<0" title="Dieser Test hat keine Fragen"></v-list-item>
  <v-list-item class="ma-2 px-2 py-5 elevation-1 bg-secondary rounded-xl" align="left" v-else lines="4" v-for="question of questions" :key="question.id" :title="question.question">
    <template #subtitle>
      <p v-for="(answer,$index) of question.answers" :key="$index" >
        <v-icon size="small" color="green" v-if="$index === question.correctAnswer">mdi-check</v-icon>
        <v-icon size="small" v-else>mdi-close</v-icon>
        <span :class="{'text-success':$index===question.correctAnswer}" v-text="answer"></span>

      </p>
    </template>
    <template #append>
      <v-btn variant="plain" icon="mdi-pencil" @click="$router.push('/admin/weiterbildung/section/'+sectionId+'/course/'+courseId+'/question/'+question.id+'/edit')"></v-btn>
    </template>
    <v-divider/>
  </v-list-item>
</v-list>
  <v-btn class="my-2" block @click="addQuestion=!addQuestion">Frage hinzufügen</v-btn>
  <v-navigation-drawer width="500" touchless temporary location="bottom" v-model="addQuestion" class="elevation-0 bg-secondary pa-0 py-2 px-2 rounded-t-xl" color="transparent" app order="2">
    <v-container>
      <v-form @submit.prevent="createQuestion">
        <v-text-field label="Question" v-model="request.question" prepend-icon="mdi-help"/>
        <v-text-field density="compact" v-for="(answer,$index) of request.answers" :key="$index" v-model="request.answers[$index].answer" :label="'Antwort '+($index+1)">
          <template #append>
            <v-checkbox hide-details v-model="request.answers[$index].correct"></v-checkbox>
          </template>
        </v-text-field>
        <v-btn block :loading="loading" type="submit" >Frage hinzufügen</v-btn>
      </v-form>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Answer from "@/models/test/answer";
import {useCoursesStore} from "@/stores/courses.module";

export default {
  name: "AdminCourseQuestions",
  setup(){
    const courseStore = useCoursesStore();
    courseStore.getAllCoursesGroupedBySection();
    return {
      courseStore
    }
  },
  data(){
    return{
      loading:false,
      addQuestion:false,
      request:{
        question:null,
        answers:[new Answer(),new Answer(), new Answer()],
        courseId:this.courseId
      }
    }
  },
  computed:{
    questions(){
      let section=this.courseStore.sections.populated[this.courseStore.sections.populated.findIndex(x=>x.id===Number(this.sectionId))];
      let course= section?.courses[section?.courses.findIndex(x=>x.id===this.courseId)]
      return course?.test?.questions
    }
  },
  props:{
    sectionId:String,
    courseId:String,
  },
  methods:{
    async createQuestion() {
      this.loading = true;
      this.request.courseId = this.courseId;
      await this.courseStore.createQuestionforCourse(this.sectionId, this.request);
      this.loading= false;
      this.addQuestion=false;
      this.courseStore.getAllCoursesGroupedBySection();
    }
  }
}
</script>

<style scoped>

</style>