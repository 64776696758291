<template>
  <div class="my-3" style="width:100%;height:200px;">
    <iframe style="width:100%;height:100%" :src="'https://www.youtube-nocookie.com/embed/'+videoId+'?si=PCTV929iGU-9jxnF'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </div>
</template>

<script>

export default {
  name: 'YoutubePlayer',
  props:{
    videoUrl:String,
  },

  data(){
    return{
      done:false
    }
  },
  created(){

  },
  computed:{
    videoId(){
      if(!this.videoUrl) return null;
      return this.getIDfromURL(this.videoUrl);
    },


  },
  methods: {

    getIDfromURL(url) {
      const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

      const match = url.match(regExp);

      if (match && match[2].length === 11) {
       return match[2];
      }

      console.log('The supplied URL is not a valid youtube URL');

      return null;
      },
  },
}
</script>

<style scoped>

</style>