export default class Address{
    constructor() {
        this.id = null;
        this.userId=null;
        this.street=null;
        this.postcode=null;
        this.city=null;
        this.country=null;
        this.federal=null;
        this.lat=null;
        this.lng=null;
    }
}