<template>
  <v-card width="250px" height="150px" style="border-radius: 20px" :class="{'successObject':object.state==='SUCCESS' || object.state === 'COINS_COLLECTED'}" class="ml-0 mr-0 pa-5 d-flex flex-wrap align-content-space-between" elevation="2">
    <v-carousel style="pointer-events: none" class="background-image" height="100%" cycle :show-arrows="false" hide-delimiters>
      <v-carousel-item
          v-for="image of object.images"
          :key="image.id"
          class="align-end"
          width="100%"
          height="100%"
          gradient="to bottom, rgba(0,0,0,.9), rgba(0,0,0,.5)"
          :src="'data:image/webp;base64,'+image.file"
          cover
      ></v-carousel-item>
    </v-carousel>
    <v-list-item class="pa-0" align="left" :subtitle="address" :title="object.objectType.replace('TYPE_','')">

    </v-list-item>
    <div style="position:relative;z-index:1;width:100%">
    <div class="text-left d-flex justify-start align-center">
      <v-skeleton-loader
          :loading="!sale"
          type="text"
          width="50px"
          color="transparent"
          class="pa-0 ma-0 simple-loader"
      >

        <span class="text-accent">€ {{coins}},-</span>

      </v-skeleton-loader>
      <small class="ml-1" v-if="sale && sale.actualValue>0"> Marktwert</small>
      <small class="ml-1" v-else> <v-dialog>
        <template #activator="{props}">
          <v-badge
             class="pa-0 ml-1 badge-no-padding"
             offset-x="5"
              v-bind="props"
              color="accent"
              content="?"
              inline
          >geschätzter Marktwert</v-badge>
        </template>
       <v-card>
         <v-card-text>
            Der geschätzte Marktwert wird anhand von verschiedenen Faktoren berechnet & kann vom Verkaufspreis abweichen.
         </v-card-text>
       </v-card>
      </v-dialog></small>
    </div>
      <div style="width:100%" class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <v-btn @click="$router.push('/immobilie/'+object.id)" variant="tonal" class="ml-1" size="small" color="grey">Öffnen</v-btn>
        </div>
        <!--<v-btn size="x-small" variant="tonal" icon color="red"><v-icon>mdi-delete</v-icon></v-btn>-->
      </div>
    </div>
    <v-chip :color="verificationStateColor" label style="position:absolute; top:5px;right:5px">
    <small v-text="stateDisplay"></small>
    </v-chip>
  </v-card>
</template>

<script>
import {getObjectName, getSale} from "@/services/object.service";
import {getVerificationState} from "@/services/requirements.service";

export default {
  name: "ObjectDisplaySmall",

  data(){
    return{
      sale:null,
      verificationState:null
    }
  },
  props:{
    object:Object
  },
  created(){
    this.getSaleObject(this.object.id);
    this.getVerificationState(this.object.id);
  },
  methods:{
    async getSaleObject(id){
      if (id) this.sale = await getSale(id);
      else this.$router.push("/");
    },
    async getVerificationState(id){
      if(id) this.verificationState= await getVerificationState(id);
    }
  },
  computed:{
    stateDisplay(){
      if(!this.verificationState) return null;
      let objectState= this.object.state;
      console.log(objectState);
      if(objectState==='SUCCESSS') return "Coins jetzt einsammeln"
      else if(objectState==='COINS_COLLECTED') return "Erfolg"
      return this.verificationState;
    },
    verificationStateColor(){
      let state = this.verificationState;
      if(state==='verifiziert') return 'success';
      else if(state==='Verifizierung läuft') return 'warning';
      else if(state==='Verifizierung fehlgeschlagen.') return 'red darken-5'
      else return 'error'
    },
    coins(){
      if(!this.sale) return null;
      if(this.sale.actualValue>0) return new Intl.NumberFormat('en-DE').format(this.sale.actualValue);
      else return new Intl.NumberFormat('en-DE').format(this.sale.value);
    },
    address(){
      return this.object.address.postcode+' '+this.object.address.city+' '+this.object.address.street;
    },
    objectName(){
      return getObjectName(this.object);
    }
  }
}
</script>

<style scoped>
.background-image{
  position: absolute;
  top: 0;
  left: 0;
  z-index:0;
  width: 100%;
  height: 100%;
}
.successObject{
  border:2px solid goldenrod;
}
</style>