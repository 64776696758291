<template>
<v-sheet class="px-5 py-10 rounded">
<div class="text-left"  v-html="objectName"></div>
  <div class="text-left" style="font-size: 2rem;" v-html="marktwert+'€'"></div>
  <div>

    <v-list v-if="!isSuccess">
    <RequirementsItem v-for="(requirement,$index) of requirements" :key="$index" :requirement="requirement"/>
    </v-list>
  </div>
  <v-btn v-if="!isSuccess" block color="accent" @click="$router.push('/admin/objekt/'+estateObject.id)">Bearbeiten</v-btn>
  <v-btn v-else block color="accent" @click="$router.push('/admin/objekt/'+estateObject.id+'?readonly')">Ansehen</v-btn>
</v-sheet>
</template>

<script>
import {getObjectName, getSale} from "@/services/object.service";
import {useObjectStore} from "@/stores/objects.module";
import RequirementsItem from "@/components/RequirementsItem";
import {getRequirements} from "@/services/requirements.service";

export default {
  name: "MarketingItem",
  components: {RequirementsItem},
  setup(){
    const objectStore = useObjectStore();



    return{
      objectStore
    }
  },
  props:{
    estateObject:Object
  },
  data(){
    return{
      sale:null,
      requirements:[]
    }
  },
  async created() {
    //TODO Requirements global not good
    this.requirements = await getRequirements(this.estateObject.id);
    this.getSale();
  },

  methods:{
    async getSale() {
      this.sale = await getSale(this.estateObject.id);
    }
  },
  computed:{
    isSuccess(){
      return this.estateObject.state==='SUCCESS';
    },

    objectName(){
      return getObjectName(this.estateObject);
    },
    marktwert(){
      if(!this.sale) return null;
      else if(this.sale.actualValue) return this.sale.actualValue;
      else return this.sale.value;
    }
  }
}
</script>

<style scoped>

</style>