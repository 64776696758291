<template>
  <StepListItem :disabled="disabled" title="Maklerprovision erhalten" :status="state.state" :message="state.message" :message-color="state.color" />

</template>

<script>
import StepListItem from "@/components/objects/progress/StepListItem";
export default {
  name: "PaymentReceivedStep",
  components: {StepListItem},
  props:{
    requirements:Array,
    state:Object
  },
  computed:{
    disabled(){
      for(let requirement of this.requirements){
        if(!requirement.value) return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>