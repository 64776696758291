<template>
  <v-navigation-drawer touchless temporary location="bottom" v-model="show" class="elevation-0 bg-secondary pa-0 py-2 px-2 rounded-t-xl" color="transparent" app order="2">
    <v-window
        v-model="drawerWindows"
    >
      <v-window-item
          :value="1"
      >
      <ProfileSettingsItem title="Admin" v-if="isAdmin" @click="goTo('/admin')" icon="mdi-lock-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Accountdaten" @click="goTo('/profil/daten')" icon="mdi-account-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Adressen" @click="goTo('/profil/addressen')" icon="mdi-map-marker-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Bankverbindung" @click="$router.push('/user/bank-details')" icon="mdi-credit-card-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Profil teilen" @click="goTo('/profil/teilen')" icon="mdi-share-variant-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Einstellungen" icon="mdi-cog-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Passwort ändern" @click="goTo('/change/password')" icon="mdi-key"></ProfileSettingsItem>
      <ProfileSettingsItem title="Achievements" @click="goTo('/achievements')" icon="mdi-trophy-outline"></ProfileSettingsItem>
      <ProfileSettingsItem title="Weiterbildungszentrale" @click="goTo('/weiterbildung')" icon="mdi-bookshelf"></ProfileSettingsItem>
      <!--ProfileSettingsItem title="Printvorlagen" icon="mdi-cloud-print-outline"></ProfileSettingsItem>
      -->
      <ProfileSettingsItem @click="drawerWindows=2" title="Dokumentvorlagen" icon="mdi-file-document-multiple-outline"></ProfileSettingsItem>

    <v-divider class="my-5"></v-divider>

      <ProfileSettingsItem @click="$router.push('/logout')" title="Log out" icon="mdi-menu-right-outline"></ProfileSettingsItem>
      </v-window-item>
      <v-window-item :value="2">
        <v-btn icon="mdi-arrow-left" variant="plain" @click="drawerWindows=1"></v-btn>
        <DownloadableList type="LEGAL"/>
      </v-window-item>
    </v-window>
  </v-navigation-drawer>
</template>

<script>
import {useAppStore} from "@/stores/app.module";
import ProfileSettingsItem from "@/components/user/ProfileSettingsItem";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import NotificationElephant from "@/models/notification";
import {useNotificationStore} from "@/stores/notification.module";
import DownloadableList from "@/components/downloadable/DownloadableList";

export default {
  name: "SettingsBottomPopup",
  components: {DownloadableList, ProfileSettingsItem},
  data(){
    return{
    drawerWindows:1
    }
  },
  setup(){
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const notificationsStore = useNotificationStore();
    return {
      appStore,
      authStore,
      notificationsStore
    }
  },
  methods:{
    close(){
      this.appStore.toggleBottomSettings();
    },
    goTo(link){
      this.appStore.toggleBottomSettings();
      this.$router.push(link);
    },
    testPush(){

      this.notificationsStore.notify(new NotificationElephant({title:"Testbenachrichtigung",message:"So sieht eine Banachrichtigung aus.",link:"/wallet"}));
    }
  },
  computed:{
    isAdmin(){
      return this.authStore.isAdmin;
    },
    show:{
      get() {
        return this.appStore.showBottomSettings;
      },
      set(value){
        this.appStore.showBottomSettings=value;
      }
      }
  }
}
</script>

<style scoped>

</style>