<template>
<HomeViewMobile v-if="$vuetify.display.mobile"/>
<HomeView v-else/>
</template>

<script>
import HomeView from "@/views/HomeView";
import HomeViewMobile from "@/views/HomeViewMobile";
export default {
  name: "HomeWrapper",
  components: {HomeViewMobile, HomeView}
}
</script>

<style scoped>

</style>