<template>
<v-dialog>
  <template #activator="{props}">
    <v-icon v-bind="props" icon="mdi-help"></v-icon>
  </template>
  <v-card>
    <v-card-text>
      Dieser Wert stellt deine Vergütung für das Zustandekommen eines Maklervertrages mit dem Eigentümer der Immobilie durch deinen Tipp an uns dar.
      Dieser berechnet sich aus der daraus entstehenden Maklerprovision, welche vom Verkaufspreis abhängig ist. Dein Anteil an der Provision des Verkäufers (Innenprovision) beträgt <v-chip>{{tippgeberProvision}}%</v-chip>
      </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "CoinFormulaDisplay",
  props:{
    marktwert:Number,
    provision:Number,
    tippgeberProvision:Number
  }
}
</script>

<style scoped>

</style>