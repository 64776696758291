<script>
import ApiService from "@/services/api.service";
import UserFullInfo from "@/components/user/UserFullInfo.vue"; // Assuming this is the path to the UserFullInfo component
import { debounce } from 'lodash';
import PaginationWrapper from "@/components/PaginationWrapper.vue";
export default {
  name: "AdminFullUserList",
  components: {
    PaginationWrapper,
    UserFullInfo
  },
  data() {
  },
  watch: {
  }
};
</script>

<template>
  <v-container>
  <pagination-wrapper url="user/info/list/page/" :page="page" :total-items="totalUsers" :page-size="pageSize" @update="onPageChange">
    <template #filter>
      <v-text-field append-inner-icon="mdi-magnify" v-model="query" label="Search" @keydown="debouncedLoadPage(1)" />
    </template>
    <template #item="{item}">
      <UserFullInfo :user="item"/>
    </template>
  </pagination-wrapper>
  </v-container>
</template>

<style scoped>
/* Add any necessary styles here */
</style>