<template>
  <div  style="height:100%; width:100%">
    <l-map ref="map" v-model:zoom="zoom" :center="marker">
      <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
      ></l-tile-layer>
     <l-marker v-if="marker" @moveend="moveCenter" v-model:lat-lng="marker" draggable> </l-marker>
      <l-circle-marker v-if="userLocation.length>1" v-model:lat-lng="userLocation" :radius="25" />
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer,LMarker,LCircleMarker } from "@vue-leaflet/vue-leaflet";


export default {
  name:"MapDisplay",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker
  },
  setup(){
    let userLocation=[];

    navigator.geolocation.getCurrentPosition(
        position => {
          console.log(position.coords.latitude);
          userLocation.push(position.coords.latitude);
          console.log(position.coords.longitude);
          userLocation.push(position.coords.longitude);
        },
        error => {
          console.log(error.message);
        }

    );
    //this.$refs.map.mapObject.
    return {
      userLocation
    }
  },
  props:{
    selectionFromParent:Array
  },
  watch:{
    selectionFromParent(){
      if(this.selectionFromParent!=null){
        console.log("Getting selected from Parent",this.selectionFromParent);
        this.selection=this.selectionFromParent;
        this.center=this.selectionFromParent;
      }
    }
  },
  methods:{
    moveSelection(e){
      console.log(e.latlng);
      //this.selection=e.sourceTarget._latlng;
    },
    moveCenter(e){
      console.log(e.sourceTarget._latlng);
      this.center=e.sourceTarget._latlng;
      this.$emit('mapUpdate',e.sourceTarget._latlng);
    },
    log(e) {
      console.log(e);
      console.log(e.sourceTarget._latlng);

    },
  },
  computed:{
    marker:{
      get(){
      if(this.selectionFromParent) return this.selectionFromParent;
      else if(this.userLocation) return this.userLocation;
      else return [0,0];
    },
    set(value){
        this.selection=value;
    }
    }
  },
  data() {
    return {
      zoom: 17,
      selection:this.userLocation,
      center:this.userLocation
    };
  },
};
</script>

<style></style>