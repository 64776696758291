<template>
  <v-btn icon @click="toggleSettingsmenu">
    <v-icon icon="mdi-dots-vertical"></v-icon>
  </v-btn>
</template>
<script>
import {useAppStore} from "@/stores/app.module";

export default {
  name: 'ToggleSettingsButton',
  setup: () => {
    const appStore = useAppStore();
    return {
      appStore
    }
  },
  methods:{
    toggleSettingsmenu(){
      this.appStore.toggleBottomSettings();
    }
  }
}
</script>