<template>
<v-app-bar v-if="$route.name!='Seite'" class="app-bar bg-primary" elevation="0" app>
  <template v-slot:prepend>
    <div class="py-2" v-if="$route.meta && $route.meta.appBarBackButton">
      <v-btn icon="mdi-chevron-left" @click="$router.back()"></v-btn>
    </div>
    <div class="pl-1" >
      <div style="width: 150px" v-if="!authStore.hasRole('ROLE_GESCHAEFTSKUNDE')">
        <ProgressBarSmall/>
      </div>
    </div>

  </template>
  <template v-slot:append>
    <ServerStateIcon/>
    <v-dialog class="text-wrap" max-width="500">
      <template #activator="{props}">
        <v-btn icon v-bind="props"><v-icon>mdi-download</v-icon></v-btn>

      </template>
      <v-card class="pa-5">
        <v-card-title v-tooltip="'Speichere die WebApp auf deinem Homescreen!'">Speichere die WebApp auf deinem Homescreen!</v-card-title>
        <v-card-subtitle></v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <ul>
            <li>
              iPhone/iPad: Tippe auf den Teilen-Button und wähle „Zum Home-Bildschirm hinzufügen“.
            </li>
            <li>
              Android: Tippe auf die drei Punkte und wähle „Zum Startbildschirm hinzufügen“.
            </li>
          </ul> </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn v-if="!authStore.hasRole('ROLE_GESCHAEFTSKUNDE')" icon @click="$router.push('/fortschritt')"><v-badge v-if="unclaimedRewards.length>0" :content="unclaimedRewards.length" color="accent"><v-icon icon="mdi-gift"></v-icon></v-badge><v-icon v-else icon="mdi-gift"></v-icon></v-btn>

    <ToggleSettingsButton/>

  </template>
</v-app-bar>
</template>

<script>
import ProgressBarSmall from "@/components/progress/ProgressBarSmall";
import {useAppStore} from "@/stores/app.module";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useRewardStore} from "@/stores/reward.module";
import {useStatusStore} from "@/stores/status.module";
import ServerStateIcon from "@/components/navigation/ServerStateIcon.vue";
import ToggleSettingsButton from "@/components/navigation/ToggleSettingsButton.vue";

export default {
  name: "TopNavigationNew",
  components: {ToggleSettingsButton, ServerStateIcon, ProgressBarSmall},
  setup(){
    const statusStore = useStatusStore();
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const rewardStore = useRewardStore();
    rewardStore.getMyRewards();

    return {
      statusStore,
      appStore,
      authStore,
      rewardStore
    }
  },
  computed:{



    unclaimedRewards(){
      return this.rewardStore.rewards.filter(x=> !x.claimed);
    },

    currentUser(){
      return this.authStore.user;
    },
    greeting(){
      let moment = require('moment');

      let time = moment().format("HH");
      if(time>=4 && time<12 ) return "Guten Morgen, "
      else if(time>=12 && time<13) return "Mahlzeit, "
      else if(time>=18 && time<23) return "Guten Abend, "
      else return "Guten Tag, "
    }
  },
  methods:{
    toggleSettingsmenu(){
      this.appStore.toggleBottomSettings();
    }
  }
}
</script>