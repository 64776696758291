import ApiService from "@/services/api.service";

export async function getAllNotifications(){
    const response = await ApiService.get("notifications/all");
    return response.data;
}
export async function notifyUser(notification){
    const response = await ApiService.post("notifications/notify/user",notification);
    if(response.status===200) return response.data;
    else return null;
}
export async function deleteNotification(notification){
    const response = await ApiService.post("notifications/delete",notification);
    if(response.status===200){
        return notification.id;
    }
    else return null;
}
export async function deleteAllNotifications(){
    const response = await ApiService.get("notifications/delete-all");
    return response;
}
export async function readNotifications(notification){
    const response = await ApiService.post("notifications/read",notification);
    if(response.status===200) return response.data;
    else return null;
}
export function sendDesktopPush(notification){

    let options={};
    if(notification.message) options.body=notification.message;
    if(notification.image) options.image=notification.image;
    if(notification.icon) options.icon=notification.icon;
    else options.icon=require('@/assets/app_icon.svg')

    if (!("Notification" in window)) {
            // Check if the browser supports notifications
            console.log("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
        new Notification(notification.title,options);

        // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    new Notification(notification.title,options);
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.

}