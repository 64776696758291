import ApiService from '@/services/api.service';

export async function getUsersAchievements(userId) {
    const response = await ApiService.get("achievement/" + userId + "/get");
    return response.data;
}
export async function getAllAchievementsByPage(page) {
    const response = await ApiService.get("achievement/page/"+page);

    return response.data;
}