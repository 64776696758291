 <template>
  <v-dialog v-model="show">
    <template #activator="{props}">
      <v-badge v-bind="props" floating>
        <div class="text-center" style="font-size: 2rem;"><span v-html="coins+'€'"></span></div>
        <template #badge>
          <v-icon icon="mdi-pencil"></v-icon>
        </template>
      </v-badge>
    </template>
    <v-card>
      <v-card-text>
      <v-text-field ref="value" @click:append="updateValue" append-icon="mdi-content-save" v-model="saleReactive.value" label="Marktwert vor Vertrag"/>
      <v-text-field ref="actualValue" @click:append="updateActualValue" append-icon="mdi-content-save" v-model="saleReactive.actualValue" label="Verkaufswert"/>
      <v-text-field ref="provision" @click:append="updateProvision" hint="Den Provisionssatz der Innenprovision aus dem Verkäufervertrag" append-icon="mdi-content-save" v-model="saleReactive.provision" label="Provision in %"/>
      <v-text-field ref="tipsterProvision" @click:append="updateTipsterProvision" append-icon="mdi-content-save" v-model="saleReactive.tipsterProvision" label="Tippgeber Provision in %"/>
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="show=false" type="text">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {updateActualValue, updateProvision, updateTipsterProvision, updateValue} from "@/services/admin.service";

export default {
  name: "ValueOverviewAdmin",
  data(){
    return{
      show:false
    }
  },
  props:{
    sale:Object
  },
  methods:{
   async updateValue() {
     let result = await updateValue(this.sale.objectId, this.saleReactive.value);
     this.$refs.value.$el.style.color="green";
     console.log("Ref",this.$refs.value.$el);
     this.$emit("update", result);
   },async updateActualValue() {
      let result = await updateActualValue(this.sale.objectId, this.saleReactive.actualValue);
      this.$refs.actualValue.$el.style.color="green";
      this.$emit("update", result);
    },async updateProvision() {
      let result = await updateProvision(this.sale.objectId, this.saleReactive.provision);
      this.$refs.provision.$el.style.color="green";
      this.$emit("update", result);
    },async updateTipsterProvision() {
      let result = await updateTipsterProvision(this.sale.objectId, this.saleReactive.tipsterProvision);
      this.$refs.tipsterProvision.$el.style.color="green";
      this.$emit("update", result);
    },
  },
  computed:{
    saleReactive(){
      return this.sale;
    },
    formula(){
      if(!this.sale) return 0;
      let value=this.sale.value;
      if(this.sale.actualValue) value=this.sale.actualValue;
      return (value*(this.sale.provision/100))*(this.sale.tipsterProvision/100);
    },
    coins(){
      return new Intl.NumberFormat('en-DE').format(Math.round(this.formula));
    },
  }

}
</script>

<style scoped>

</style>