<template>
  <v-container>
    <v-form v-if="section" @submit.prevent="updateSection">
      <!--<v-text-field type="number" label="Nummer" hint="Nach dieser Nummer werden die Sektionen sortiert." v-model="section.id"/>-->
      <v-text-field type="text" label="Titel" v-model="section.title"/>
      <v-textarea type="text" label="Beschreibung" v-model="section.description"/>
      <v-btn type="submit" block>Sektion aktualisieren</v-btn>
    </v-form>
  </v-container>
</template>

<script>

import {getSectionByIdUnpopulated, updateSection} from "@/services/courses.service";

export default {
  name: "EditSection",
  props:{
    sectionId:Number
  },
  data(){
    return{
    section:null
    }
  },
  created() {
    this.getSection();
  },
  methods:{
    async getSection() {
      this.section = await getSectionByIdUnpopulated(this.sectionId)
    },
    updateSection(){
      updateSection(this.section);
      this.courseStore.getAllCoursesGroupedBySection();
    }
  }
}
</script>

<style scoped>

</style>