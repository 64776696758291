<template>
<v-dialog v-model="show" :fullscreen="!isIdentified">
  <v-card v-if="isIdentified && !reIdentify">
    <v-card-text>
      Dein Account ist verifiziert. Du kannst die App nun uneingeschränkt nutzen.
    </v-card-text>
    <v-card-actions>
      <v-btn @click="reIdentify=true">Neu Identifizieren</v-btn>
    </v-card-actions>
  </v-card>
  <AddIdentificationDocument v-else @close="closeDialog"/>
</v-dialog>
</template>

<script>
import AddIdentificationDocument from "@/components/identification/AddIdentificationDocument";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useAppStore} from "@/stores/app.module";
export default {
  name: "AddIdentificationDocumentDialogGlobal",
  components: {AddIdentificationDocument},
  setup(){
    const authUserStore = useAuthUserStore();
    const appStore = useAppStore();
    authUserStore.checkIdentificationStatus();
    return{
      authUserStore,
      appStore
    }
  },
  methods:{
    closeDialog() {
    this.appStore.toggleIdentificationDialog();
    }
  },
  data(){
    return{
      reIdentify:false,

    }
  },
  computed:{
    show:{
      get() {
        return this.appStore.showIdentification;
      },set(){
        this.appStore.toggleIdentificationDialog();
      }
    },
    isIdentified(){

      return this.authUserStore.status.identified;

    }
  }
}
</script>

<style scoped>

</style>