<template>
  <v-app id="app">
    <TopNavigationNew v-if="authUserStore.status.loggedIn && !$route.meta.hideHeader"/>
      <v-main class="bg-primary" style="position: relative">
      <NotificationWrapper v-if="authUserStore.status.loggedIn"/>
        <ConfirmationTokenBanner v-if="authUserStore.status.loggedIn && !isUserEnabled"/>
        <router-view id="v-step-0"/>
      </v-main>

    <AppNavigation v-if="!$route.meta.hideFooter && !authUserStore.hasRole('ROLE_GESCHAEFTSKUNDE')"/>
    <v-footer v-if="!$route.meta.hideFooter" class="bg-white" app height="20" order="0" ></v-footer>
    <SettingsBottomPopup/>
    <div v-if="authUserStore.status.loggedIn">
      <NewRewardPopup v-for="reward of progressUserStore.newRewards" :key="reward.id" :reward="reward"/>

    </div><!--<DailyRaffleDisplay v-if="userCanRaffle"/>-->
    <AddIdentificationDocumentDialogGlobal v-if="authUserStore.status.loggedIn"/>
  </v-app>
  </template>
<script>
import { defineComponent } from 'vue'
import { useAuthUserStore } from '@/stores/modules/auth.module';
import AppNavigation from "@/components/navigation/AppNavigation";
import {useUserProgressStore} from "@/stores/progress.module";
import SettingsBottomPopup from "@/components/navigation/SettingsBottomPopup";
import NewRewardPopup from "@/components/progress/NewRewardPopup";
import {useRewardStore} from "@/stores/reward.module";
import AddIdentificationDocumentDialogGlobal from "@/components/identification/AddIdentificationDocumentDialogGlobal";
import {useUserStore} from "@/stores/user.module";
import TopNavigationNew from "@/components/navigation/TopNavigationNew";
import NotificationWrapper from "@/components/notification/NotificationWrapper";
import {useStatusStore} from "@/stores/status.module";
import ConfirmationTokenBanner from "@/components/auth/ConfirmationTokenBanner";

export default defineComponent({
  components: {
    ConfirmationTokenBanner,
    NotificationWrapper,
    TopNavigationNew,
    AddIdentificationDocumentDialogGlobal, NewRewardPopup, SettingsBottomPopup, AppNavigation},
  setup () {
    const statusStore = useStatusStore();
    statusStore.checkServerState();
    const authUserStore = useAuthUserStore()
    const progressUserStore= useUserProgressStore();
    const rewardStore = useRewardStore();
    const userStore = useUserStore();



    if(authUserStore.status.loggedIn){
      rewardStore.canUserRaffle();
      progressUserStore.getProgress();
      userStore.getMyFreeSlots();

    }

    console.log(navigator.connection)
    console.log(navigator.language);

    return {
      // you can also access the whole store in your component by returning it
      authUserStore,progressUserStore,rewardStore
    }
  },
  data(){
    return{
      steps: [
      ]
    }
  },
  computed:{
    userCanRaffle(){
      return this.rewardStore.canRaffle;
    },
    isUserEnabled(){
      return this.authUserStore.user.emailVerified;
    }
  },
  methods: {
    onScroll (e) {
      console.log(e)
    },
  },
  created(){
    //if(!this.authUserStore.status.loggedIn) this.$router.push("/login");
  },
  mounted() {
  }
})
</script>
<style>


nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
