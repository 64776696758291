<template>
  <v-skeleton-loader type="list-item-avatar-two-line" color="transparent" :loading="!section">
<v-list-item style="width:100%" :title="section.title" :subtitle="section.description">
<template #prepend>
  <v-avatar>
    {{romanize(section.id)}}
  </v-avatar>
</template>
  <template #append>
    <v-btn
        variant="plain"
        icon="mdi-dots-vertical"
        @click.stop="show = !show"
    >
    </v-btn>
  </template>
</v-list-item>
  </v-skeleton-loader>
  <v-navigation-drawer temporary location="bottom" v-model="show" class="elevation-0 bg-secondary pa-0 py-2 px-2 rounded-t-xl" color="transparent" app order="2">
    <ProfileSettingsItem title="Kurse ansehen" @click="$router.push('/admin/weiterbildung/section/'+section.id+'/course')" icon="mdi-animation-outline"></ProfileSettingsItem>
    <ProfileSettingsItem title="Bearbeiten" @click="$router.push('/admin/weiterbildung/section/'+section.id+'/edit')" icon="mdi-cog-outline"></ProfileSettingsItem>

    <ProfileSettingsItem title="Löschen" @click="deleteSection" icon="mdi-delete-outline"></ProfileSettingsItem>
  </v-navigation-drawer>
</template>

<script>
import {romanize} from "@/services/romanize.service";
import ProfileSettingsItem from "@/components/user/ProfileSettingsItem";
import {useCoursesStore} from "@/stores/courses.module";

export default {
  name: "CourseSectionItem",
  components: {ProfileSettingsItem},
  setup(){
    const courseStore = useCoursesStore();
    return{
      courseStore
    }
  },
  props:{
    section:Object
  },
  data(){
    return{
      show:false
    }
  },
  methods:{
    romanize(num){
      return romanize(num)
    },
    deleteSection(){
      this.courseStore.deleteSection(this.section.id);
    }
  }
}
</script>

<style scoped>

</style>