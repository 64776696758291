<template>
<div class="d-flex flex-column">
  <NotificationSnackbar v-for="(notification,$index) of newNotifications" :key="$index" :index="$index" :notification="notification"/>

</div>
</template>

<script>
import NotificationSnackbar from "@/components/notification/NotificationSnackbar";
import {useNotificationStore} from "@/stores/notification.module";
export default {
  name: "NotificationWrapper",
  components: {NotificationSnackbar},
  setup(){
    const notificationsStore = useNotificationStore();
    notificationsStore.getAllNotifications();
    return{
      notificationsStore
    }
  },
  computed:{
    newNotifications(){
      return this.notificationsStore.getNewNotifications;
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>