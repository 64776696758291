<template>
  <v-dialog v-model="showReactive" >
    <template #activator="{props}">
      <StepListItem v-bind="props" :disabled="disabledC" title="Marklervertrag abgeschlossen" :message="state.message" :message-color="state.color" :status="state.state" />

    </template>
  <v-card>
    <v-card-text>Der Maklervertrag mit dem Besitzer ist <span class="success">aktiv</span>.<br/>
    Wir haben jetzt bis zum <v-chip ><span v-text="contract.cancellationPeriodStart"></span></v-chip> Zeit, um "deine" Immobilie zu vermarkten.
    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
import StepListItem from "@/components/objects/progress/StepListItem";
import {getContract} from "@/services/object.service";
export default {
  name: "ContractStep",
  components: {StepListItem},
  props:{
    show:Boolean,
    requirements:Array,
    statusRequirements:Object,
    state:Object,
    objectId:Number
  },
  data(){
    return{
      contract:null
    }
  },
  created() {
  this.getContract()
  },
  methods:{
    async getContract() {
      this.contract = await getContract(this.objectId);
    }
  },
  computed:{
    showReactive:{
      get(){
        return this.show;
      },set(value){
        this.$emit('toggleDialog',value)
      }
    },
    message(){
      return{
        color:'text-error',
        text:this.statusRequirements?.success?.label
      }
    },

    disabledC(){
      for(let requirement of this.requirements){
        if(!requirement.value) return true;
      }
      return false;
    }
  }
  //TODO SUbtitle = vereinbarter Verkaufswert. Bei Klick Vertrag öffnen? Nur ywichtigste Daten?
}
</script>

<style scoped>

</style>