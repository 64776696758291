<template>
  <div class="flex-grow-1 mr-1">
    <v-progress-linear rounded color="accent" :model-value="(progress?.pointsInCurrentLevel/progress?.pointsToNextLevel)*100" height="14px">
      <template v-slot:default>
        <small style="font-size: 0.5rem;" class="font-weight-bold">{{progress?.pointsInCurrentLevel}} /
          {{progress?.pointsToNextLevel}}</small>
      </template>

    </v-progress-linear>
  </div>
  <span style="font-size:0.7rem">Level {{progress?.currentLevel}}</span>
</template>
<script>
export default {
  name: 'ProgressBarViewSmall',
  props:{
    progress:Object
  }
}
</script>