<template>
  <div>
    <v-skeleton-loader color="transparent" class="skeleton-rounded my-3 my-md-0" :loading="loading">
      <CourseItem :course="latest.course" :section="latest.section"/>
    </v-skeleton-loader>
  </div>
</template>

<script>
import {findMyLatestCourse} from "@/services/courses.service";
import CourseItem from "@/components/courses/CourseItem";
import {useCoursesStore} from "@/stores/courses.module";

export default {
  setup(){
  const coursesStore = useCoursesStore()
  return{
    coursesStore
  }
  },
  name: "LatestCourse",
  components: {CourseItem},
  data(){
    return{
      latest:null,
      loading:false,
    }
  },
  computed:{
    courses(){
      return this.coursesStore.user.courses;
    }
  },
  watch:{
    courses(){
      this.getLatest();
    }
  },
  created() {
    this.getLatest();
  },
  methods:{
    async getLatest() {
      this.loading=true;
      this.latest = await findMyLatestCourse();
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>