<template>
<v-card>
  <v-card-text>
    <v-card-title>Identifizierung</v-card-title>
    <div>Wir stehen für <b>Transparenz</b>. Daher ist es wichtig, dass bestimmte Funktionen der App nur von <b>vertrauenswürdigen Personen</b> genutzt werden können.
      Verifiziere jetzt deinen Account, um alle Funktionen der App nutzen zu können.
    </div>
    <small class="mt-1">*Die Daten werden verschlüsselt auf dem Server gespeichert & werden nur im Falle einer Misshalndlung unserer Nutzungsrichtlinien herangezogen.</small>

  </v-card-text>
<v-divider/>
  <v-card-text>

    <v-form validate-on="blur" ref="formAusweis">
  <v-select :rules="[rules.required]" item-title="label" v-model="identificationDoc.docType" item-value="value" :items="docTypes">

  </v-select>
    <v-select :rules="[rules.required]" v-model="identificationDoc.docCountry" :items="countries">

  </v-select>

    <v-text-field hint="Vorname Nachname | Keine 2. Namen" v-model="identificationDoc.fullName" label="Name auf dem Ausweis"></v-text-field>
    <div v-if="identificationDoc.docType==='GERMAN_IDENTITY_CARD' || identificationDoc.docType==='GERMAN_PASSPORT' || identificationDoc.docType==='GERMAN_IDENTITY_CARD_NEW'">
      <v-img class="mb-2" width="100%" :src="identityCardExample" alt="Hilfe"></v-img>
<v-text-field  :rules="[rules.required,character(10)]"  @focus="focusedInput=1" label="Ausweisnummer" v-model="identificationDoc.docNumber"></v-text-field>
<v-text-field :rules="[rules.required,character(7)]" @focus="focusedInput=2" label="Block 2" v-model="identificationDoc.birthDate"></v-text-field>
<v-text-field v-if="identificationDoc.docType==='GERMAN_IDENTITY_CARD_NEW'" :rules="[rules.required]" @focus="focusedInput='extra'" label="Versionsnummer" v-model="identificationDoc.version"></v-text-field>

      <v-text-field :rules="[rules.required]" @focus="focusedInput=3" label="Block 3" v-model="identificationDoc.validUntil"></v-text-field>
<v-text-field :rules="[rules.required]" @focus="focusedInput=4" label="Block 4" v-model="identificationDoc.checkSum"></v-text-field>
  </div>

</v-form>
  </v-card-text>
  <v-card-actions>
    <v-btn variant="text" @click="closeDialog">Abbrechen</v-btn>
    <v-spacer/>
    <v-btn @click="requestIdentification" color="success">Speichern</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import IdentificationDocument from "@/models/identificationDocument";
import {requestIdentification} from "@/services/identification.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";

export default {
  name: "AddIdentificationDocument",
  setup(){
    const authUserStore = useAuthUserStore();
    return{
      authUserStore
    }
  },
  data(){
    return{
      identificationDoc: new IdentificationDocument(),
      docTypes:[{value:"GERMAN_IDENTITY_CARD",label:"Ausweis"},
        {value:"GERMAN_PASSPORT",label:"Reisepass"},
        {value:"GERMAN_IDENTITY_CARD_NEW",label:"Ausweis Neu"},
      ],
      countries:["Deutschland"],
      focusedInput: 0,
      rules:{
        required: value => {
          if (value) return true

          return 'Dieses Feld ist Pflicht.'
        },
      }
    }
  },
  computed:{
    blockThreeCharacters(){
      if(this.identificationDoc.docType==='GERMAN_IDENTITY_CARD') return 7;
      else if(this.identificationDoc.docType==='GERMAN_PASSPORT') return null;
      else return 100;
    },
    identityCardExample(){
      return require("@/assets/"+this.identificationDoc.docType+"_"+this.focusedInput+".webp")
    }
  },
  methods:{
    character(length){
      if(!length) return true;
      return v => (v && v.length == length) || 'Keine gültige Eingabe.';
    },
    closeDialog(){
      this.$emit("close");
    },
    async requestIdentification() {

      const form = await this.$refs.formAusweis.validate();
      if(!form.valid) return false;

      let request = this.identificationDoc;
      const response = await requestIdentification(request);
      this.authUserStore.checkIdentificationStatus();
      console.log(response);
      this.closeDialog();
    }
  }
}
</script>

<style scoped>

</style>