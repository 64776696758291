<script>
export default {
  name: "SelectUserField",
  computed: {
    userIdR: {
      get() {
        return this.userId;
      },
      set(value) {
        this.$emit("update:userId", value);
      }
    }
  },
  props: {
    placeholder: {
      type: String,
      default: "Nutzer auswählen"
    },
    users: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<template>
<v-autocomplete :placeholder="placeholder" :disabled="disabled" no-data-text="Keine Nutzer gefunden" :loading="loading" :items="users" v-model="userIdR" item-title="email" item-value="id"></v-autocomplete>
</template>

<style scoped>

</style>