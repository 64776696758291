// Pinia Store
import { defineStore } from 'pinia'
import {
    addCoinsTransaction,
    addTokenTransaction, changeTokenToCoins,
    getHistoryCompareValueCoins,
    getMyWallet,
    getMyWalletHistory, payout
} from "@/services/wallet.service";

const state = () => ({
    wallet:{
        coins:0,
        token:0
    },
    historyCompareCoinsValue:null,
    history:[]
});
export const useWalletStore = defineStore('userWallet',{
    state: state,



// getters
    getters: {

    },

// actions
    actions:{
        async getCoinsCompareValue(){
          const response = await getHistoryCompareValueCoins();
          this.historyCompareCoinsValue= response;
        },
        async getMyWallet(){
          const response = await getMyWallet();
          this.wallet=response;
        },
        async getWalletHistory(){
            const response = await getMyWalletHistory();
            //console.log("Response",response);
            this.history = response
        },

        async addCoinsTransaction(amount, subject) {
            const response = await addCoinsTransaction(amount, subject);
            this.wallet = response;
            return response;

        },
        async changeTokenToCoins(token){
          const response = await changeTokenToCoins(token);
          this.wallet=response;
          return response;
        },
        async addTokenTransaction(amount, subject) {
            const response = await addTokenTransaction(amount, subject);
            this.wallet = response;
            return response;
        },
        async payout(amount){
            const response = await payout(amount);
            console.log("Payout response",response);
            this.wallet.coins=response;
        }

    }
});