<template>
  <v-skeleton-loader type="list-item-avatar-three-line" color="transparent" :loading="!course">
<v-list-item style="width:100%" lines="3" class="rounded-xl bg-blue my-2" align="left" :title="course.title">
  <template #prepend>
    <v-avatar>
      {{course.chapter}}.
    </v-avatar>
  </template>
  <template #subtitle>
    <p>{{course.description}}</p>
    <p>
      <v-icon v-if="!course.videoUrl" size="small">mdi-video-off</v-icon>
      <v-icon v-else color="green" size="small">mdi-video</v-icon>
      <v-icon :color="questionsColor" size="small">mdi-help-box-multiple-outline</v-icon>
      <span> - {{course.publishStatus}}</span>
    </p>
    </template>
  <template #append>

    <v-btn
        variant="plain"
        icon="mdi-dots-vertical"
        @click.stop="show = !show"
    >
    </v-btn>
    <v-btn
        v-if="showCourse"
        variant="plain"
        icon="mdi-chevron-up"
        @click.stop="showCourse = false"
    >
    </v-btn>

  </template>

</v-list-item>
  <v-navigation-drawer touchless temporary location="bottom" v-model="show" class="elevation-0 bg-secondary pa-0 py-2 px-2 rounded-t-xl" color="transparent" app order="2">
    <v-window
        v-model="drawerWindows"
    >
      <v-window-item
          :value="1"
      >
    <ProfileSettingsItem @click="showCourseF" title="Sektion ansehen" icon="mdi-eye-outline"></ProfileSettingsItem>
    <ProfileSettingsItem title="Fragen" @click="$router.push('/admin/weiterbildung/section/'+sectionId+'/course/'+course.id+'/question')" icon="mdi-help-box-multiple-outline"></ProfileSettingsItem>
    <ProfileSettingsItem title="Video hinzufügen" @click="drawerWindows=5" icon="mdi-video-plus"></ProfileSettingsItem>
    <ProfileSettingsItem title="Fließtext hinzufügen" @click="drawerWindows=6" icon="mdi-text-box-plus-outline"></ProfileSettingsItem>
        <ProfileSettingsItem title="Einleitungstext" @click="drawerWindows=3" icon="mdi-text-box-outline"></ProfileSettingsItem>
    <ProfileSettingsItem title="Sichtbarkeit" @click="drawerWindows=4" icon="mdi-eye-lock-outline"></ProfileSettingsItem>
    <ProfileSettingsItem @click="deleteCourse" title="Löschen" icon="mdi-delete-outline"></ProfileSettingsItem>
      </v-window-item>
      <v-window-item :value="2">
        <v-btn variant="plain" @click="drawerWindows=1" icon="mdi-arrow-left"></v-btn>
        <v-file-input accept="video/*" v-model="uploadedVideo" label="Video"></v-file-input>
        <v-btn block :loading="uploadingVideo" :disabled="!uploadedVideo" @click="uploadVideo">Video hochladen</v-btn>
      </v-window-item>
      <v-window-item :value="5">
        <v-btn variant="plain" @click="drawerWindows=1" icon="mdi-arrow-left"></v-btn>
        <v-text-field v-model="videoUrl" label="Video Url anpassen"></v-text-field>
        <v-btn block :loading="savingVideoUrl" @click="saveVideoUrl">Speichern</v-btn>
      </v-window-item>
      <v-window-item :value="3">
        <v-btn variant="plain" @click="drawerWindows=1" icon="mdi-arrow-left"></v-btn>
       <v-textarea label="Einleitungstext bearbeiten" @click:appendInner="editDescription" append-inner-icon="mdi-content-save" v-model="descriptionRequest.description"></v-textarea>
      </v-window-item>
      <v-window-item :value="4">
        <v-btn variant="plain" @click="drawerWindows=1" icon="mdi-arrow-left"></v-btn>
        <v-select :items="publishStatus" append-icon="mdi-content-save" @click:append="changeStatus" item-value="value" item-title="name" v-model="editablePublishStatus"></v-select>
      </v-window-item>
      <v-window-item :value="6">
        <v-btn variant="plain" @click="drawerWindows=1" icon="mdi-arrow-left"></v-btn>
      <VuetifyTiptap style="text-align: left" v-model="text"/>
        <v-btn @click="setText">Speichern</v-btn>
        <!--<v-textarea append-icon="mdi-content-save" @click:append="setText" v-model="text" label="Fließtext bearbeiten"></v-textarea>
     --> </v-window-item>
    </v-window>
  </v-navigation-drawer>
  </v-skeleton-loader>
  <v-slide-y-transition>
    <div v-if="showCourse">
      <CourseSite :course="{course:course}">

      </CourseSite>
    </div>
  </v-slide-y-transition>
</template>

<script>
import {VuetifyTiptap} from "vuetify-pro-tiptap";
import ProfileSettingsItem from "@/components/user/ProfileSettingsItem";
import {
  addVideoUrl,
  changePublishStatusCourse,
  deleteCourse,
  editDescription, setText,
  uploadVideoToCourse
} from "@/services/courses.service";
import CourseSite from "@/components/courses/CourseSite";
import {useCoursesStore} from "@/stores/courses.module";
export default {
  name: "AdminCourseItem",
  components: {CourseSite, ProfileSettingsItem},
  props:{
    course:Object,
    sectionId:Number
  },
  setup(){
    const coursesStore = useCoursesStore();
    return {coursesStore};
  },
  computed:{
    questionsColor(){
      if(this.course.test.questions.length>0) return "success";
      else return null
    }
  },
  data(){
    return{
      text:this.course?.text,
      videoUrl:this.course?.videoUrl,
      savingVideoUrl:false,
      editablePublishStatus:this.course.publishStatus,
      publishStatus:[

        {
          name:'Entwurf',
          value:"ENTWURF"
        },
        {

          name:"Veröffentlicht",
          value:"VERÖFFENTLICHT",
        },{
          name:"Archiviert",
          value:"ARCHIVIERT",
        },{
          name:"Gelöscht",
          value:"GELÖSCHT",
        },{
          name:"Verifizierung ausstehend",
          value:"VERIFIZIERUNGAUSSTEHEND",
        },
      ],
      showCourse:false,
      show:false,
      drawerWindows:1,
      videoRequest:{
        file:null
      },
      descriptionRequest:{
        description:this.course.description
      },
      uploadedVideo:null,
      uploadingVideo:false
    }
  },
  methods:{
    deleteCourse(){
      deleteCourse(this.course.id);
    },
    showCourseF(){
      this.showCourse=!this.showCourse;
      this.show=false;
    },
    async changeStatus(){
      let result =await changePublishStatusCourse(this.course.id, this.editablePublishStatus);
      if(result) await this.coursesStore.getAllCoursesGroupedBySection();
    },
    async saveVideoUrl(){
      this.savingVideoUrl=true;
      let course = await addVideoUrl(this.course.id,this.videoUrl);
      if(course){
        this.savingVideoUrl=false;
      }
      await this.coursesStore.getAllCoursesGroupedBySection();
      this.savingVideoUrl=false;
    },
    async setText(){
      this.savingText=true;
      let text = await setText(this.course.id, this.text);
      if(text){
        this.savingText=false;
      }
      await this.coursesStore.getAllCoursesGroupedBySection();
      this.savingText=false;
    },
    async uploadVideo() {
      this.uploadingVideo=true;
      this.videoRequest.file= this.uploadedVideo[0];
      let course = await uploadVideoToCourse(this.course.id, this.videoRequest);
      if(course) {
        console.log("Erfolgreich hochgeladen");
        this.uploadingVideo=false;
      }
      await this.coursesStore.getAllCoursesGroupedBySection();
    },
    async editDescription(){
      let result = await editDescription(this.course.id,{string:this.descriptionRequest.description});
      console.log(result);
      await this.courseStore.getAllCoursesGroupedBySection();
    }
  }
}
</script>

<style scoped>
.background{
  background: linear-gradient(12deg, rgba(254,254,254,0) 0%, rgba(255,255,255,0.15) 95%);
}
</style>