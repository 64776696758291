import ApiService from "@/services/api.service";
import axios from "axios";
import authHeader from "@/services/auth-header";
import host from "./host";


const basexp = 100;
const scalingFactor = 1.5;
export function calculateXPNeededForNextLevel(level){
    return calculatetotalEarnedPointsEarnedToLevel(level+1)-calculatetotalEarnedPointsEarnedToLevel(level);
}
export function calculatetotalEarnedPointsEarnedToLevel(level){
    return Math.floor(basexp * ((Math.pow(scalingFactor,level) - 1) / (scalingFactor - 1)));
}
export function getCurrentLevel(currentExp){

   return Math.floor(Math.log((currentExp * (scalingFactor - 1) / basexp) + 1) / Math.log(scalingFactor));
}

export async function getProgress(userId) {
    const response = await ApiService.get("user/progress/get/"+ userId);
    return response.data;
}
export async function getProgressWithoutApiService(userId) {
    let url="user/progress/get/"+userId;

    const API_URL = host+'/api/';

    const response = await axios.get(API_URL + url, {headers: authHeader()});
    if(response.status===200) return response.data;
    else return null;
}
