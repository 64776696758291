import ApiService from "@/services/api.service";

export async function getSoldLivingArea() {
    const response = await ApiService.get("object/mine/sum/sold/living");
    return response.data;
}

export async function getAdminStatistics(){
    const response = await ApiService.get("statistics/get");
    return response.data;
}