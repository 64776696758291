// Pinia Store
import { defineStore } from 'pinia';
import {
    createQuestionForCourse,
    deleteSection, editQuestion, findAllUsersCoursesBySection,
    getAllSections,
    getAllSectionsPopulated
} from "@/services/courses.service";

const state = () => ({
        sections:{
            populated:[],
            unpopulated:[]
        },
        user:{
            courses:[]
        }
});
export const useCoursesStore = defineStore('coursesStore',{
    state: state,



// getters
    getters: {

    },

// actions
    actions:{
        async getAllCoursesGroupedBySection() {
            this.sections.populated = await getAllSectionsPopulated();
        },
        async deleteSection(id){
            let resultId = await deleteSection(id);
            if(resultId){
                let list=this.sections.populated;
                this.sections.populated.splice(list.indexOf(x=>x.id ===id),1);
            }
        },
        async getAllSections() {
            this.sections.unpopulated = await getAllSections();
        },
        async createQuestionforCourse(sectionId,request){
            const result= createQuestionForCourse(request);
            let section = this.sections.populated[this.sections.populated.indexOf(x=>x.id===sectionId)];
            let course = section.courses[section.courses.indexOf(x=>x.id===request.courseId)];
            course.test.questions.push(result);

        },
        async editQuestion(sectionId,questionId,request){
            const result= editQuestion(questionId,request);
            let section = this.sections.populated[this.sections.populated.indexOf(x=>x.id===sectionId)];
            let course = section.courses[section.courses.indexOf(x=>x.id===request.courseId)];
            let question= course.test.questions[course.test.questions.indexOf(x=>x.id===questionId)];
            console.log(question);
            console.log("Frage nach Update",course.test.questions[course.test.questions.indexOf(x=>x.id===questionId)])
            question=result;
        },
        //User
        async findAllUserCoursesBySection(sectionId){
            const result = await findAllUsersCoursesBySection(sectionId)
            this.user.courses=result;
        }
    }
});