<template>
  <v-tabs
      v-model="tab"
      bg-color="transparent"
  >
    <v-tab value="one">
      Verifizierungen
    </v-tab>
    <v-tab value="two">Vermarktung</v-tab>
    <v-tab value="three">Erfolg</v-tab>
    <v-tab value="four">Fehler</v-tab>
  </v-tabs>

  <v-window v-model="tab">
    <v-window-item value="one">
      <VerificationList/>
    </v-window-item>

    <v-window-item value="two">
      <v-container>
        <MarketingList :objects="objectsPending"/>

      </v-container>
    </v-window-item>

    <v-window-item value="three">
      <v-container>
        <MarketingList :objects="objectsSuccess"/>

      </v-container>
    </v-window-item>
    <v-window-item value="four">
      <v-container>
        <MarketingList :objects="objectsCancelled"/>

      </v-container>
    </v-window-item>
  </v-window>

</template>

<script>
import VerificationList from "@/components/admin/VerificationList";
import MarketingList from "@/components/admin/vermarktung/MarketingList";
import {useAdminStore} from "@/stores/admin.module";
export default {
  name: "AdminOverview",
  components: {MarketingList, VerificationList},
  setup(){
    const adminStore = useAdminStore();
    adminStore.getPendingEstateObjects();
    adminStore.getCancelledEstateObjects();
    adminStore.getSuccessEstateObjects();
    return{
      adminStore
    }
  },
  computed:{
    objectsPending(){
      return this.adminStore.pendingEstateObjects;
    },
    objectsCancelled(){
      return this.adminStore.cancelledEstateObjects;
    },
    objectsSuccess(){
      return this.adminStore.successEstateObjects;
    },
  },
  data(){
    return{
      tab:null
    }
  }
}
</script>

<style scoped>

</style>