<template>
<v-container>
  <v-form>
    <v-text-field v-model="request.question" label="Question" prepend-icon="mdi-help"/>
    <p>Antworten</p>
    <v-text-field v-for="(answer,$index) of request.answers" :key="$index" v-model="request.answers[$index].answer" :label="'Antwort '+($index+1)">
      <template #append>
        <v-checkbox hide-details v-model="request.answers[$index].correct"></v-checkbox>
      </template>
    </v-text-field>
  </v-form>
</v-container>
</template>

<script>
import Answer from "@/models/test/answer";

export default {
  name: "CreateQuestion",
  props:{
    sectionId:Number,
    courseId:String
  },
  data(){
    return{
      request:{
        question:null,
        answers:[new Answer(),new Answer(), new Answer()],
        courseId:null
      }
      }
  }
}
</script>

<style scoped>

</style>