<template>
  <v-dialog max-width="500">
    <template #activator="{props}">
      <v-list-item v-bind="props" :title="-payout.requestedAmount+' | '+user.firstName+' '+user.lastName" :subtitle="bankDetails?.iban" :prepend-avatar="user.id">

      </v-list-item>
    </template>
    <v-card>

<v-card-text>
  <p>Betreff: Auszahlung EstateBoost an {{user.firstName}} {{user.lastName}} | Transaktion-ID {{payout.transactionId}} </p>

  <p>Betrag: {{-payout.requestedAmount}} €</p>
  <p>Derzeitiger Status: {{payout.state}}</p>
  <br/>
      <CardView v-if="bankDetails" :bank-details="bankDetails"/>
  <p v-else>Bankverbindung fehlt!</p>
</v-card-text>
      <v-card-actions>
        <v-btn color="success" variant="elevated" @click="changeStatus('DONE')">Überwiesen</v-btn>
        <v-btn @click="changeStatus('PENDING')" variant="plain">Wartend</v-btn>
        <v-btn color="error" @click="changeStatus('FAILED')" variant="plain">Fehler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import CardView from "@/components/user/CardView";
import {changePayoutState} from "@/services/payout.service";
export default {
  name: "PayoutItem",
  components: {CardView},
  props:{
    payout:Object
  },
  methods:{
    async changeStatus(status){
      let status2 = await changePayoutState(this.payout.id,status);
      alert('Status erfolgreich geändert');
      location.reload();
    }
  },
  computed:{
    user(){
      return this.payout.user;
    },
    bankDetails(){
      return this.payout.bankDetails;
    },
    transaction(){
      return this.payout.transaction;
    }
  }
}
</script>

<style scoped>

</style>