
import ApiService from "@/services/api.service";
export async function addCoinsTransaction(amount, subject) {
    const response = await ApiService.post("wallet/transaction/coins", {amount: amount, subject: subject});
    return response.data;
}
export async function changeTokenToCoins(token) {
    const response = await ApiService.post("wallet/change/token", {amount: token, subject: null});
    return response.data;
}
export async function addTokenTransaction(amount, subject) {
    const response = await ApiService.post("wallet/transaction/token", {amount: amount, subject: subject});
    return response.data;
}
export async function getMyWallet() {
    const response = await ApiService.get("wallet/self/get");
    return response.data;
}
export async function getMyWalletHistory() {
    const response = await ApiService.post("wallet/history/get");

    return response.data.sort((x, y) => {
        return new Date(x.timestamp) < new Date(y.timestamp) ? 1 : -1
    }).reverse();
}
export async function getMyWalletHistoryDataSet(start,end) {
    const response = await ApiService.post("wallet/history/chart/data",{
        start:start,
        end:end
    });
    return response.data;
}
export async function getHistoryCompareValueCoins() {
    const response = await ApiService.get("wallet/history/compare/coins");
    return response.data;
}
export async function getFirstHistoryTimestamp() {
    const response = await ApiService.get("wallet/history/min");
    return response.data;
}
export async function payout(amount) {
    const response = await ApiService.post("payout/request",{amount:amount});
    return response.data;
}
export async function getMyPayouts(){
    const response = await ApiService.get("payout/my");
    console.log("Response",response);
    return response.data;
}
