<script>
import UserAvatar from "@/components/user/UserAvatar.vue";
import CoinsIcon from "@/icons/CoinsIcon.vue";
import TokenIcon from "@/icons/TokenIcon.vue";
import ProgressBarViewSmall from "@/components/user/ProgressBarViewSmall.vue";

export default {
  name: "UserFullInfo",
  components: {ProgressBarViewSmall, TokenIcon, CoinsIcon, UserAvatar},
  props: {
    user: Object
  }
}
</script>

<template>
  <v-dialog>
    <template #activator="{ props }">
      <v-sheet max-width="100%" class="ma-2 pa-5" v-bind="props">
        <v-row>
          <v-col cols="12" md="6">
            <UserAvatar tile size="70" :user="user"/>
            <v-row>
              <v-col>
                <h4>{{ user.fullName }}</h4>
                <small variant="subtitle1">
                  {{ user.email }}
                  <v-tooltip v-if="user.emailVerified" bottom>
                    <template v-slot:activator="{ props }">
                      <v-icon color="green" v-bind="props">mdi-check-circle</v-icon>
                    </template>
                    <span>Email Verified</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ props }">
                      <v-icon color="red" v-bind="props">mdi-alert-circle</v-icon>
                    </template>
                    <span>Email Not Verified</span>
                  </v-tooltip>
                </small>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tooltip v-if="user.identificationVerified" bottom>
                  <template v-slot:activator="{ props}">
                    <v-icon color="green" v-bind="props">mdi-account-check</v-icon>
                  </template>
                  <span>Identification Verified</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon color="red" v-bind="props">mdi-account-cancel</v-icon>
                  </template>
                  <span>Identification Not Verified</span>
                </v-tooltip>
                <v-tooltip v-if="user.enabled" bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon color="green" v-bind="props">mdi-account</v-icon>
                  </template>
                  <span>Enabled</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon color="red" v-bind="props">mdi-account-off</v-icon>
                  </template>
                  <span>Disabled</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CoinsIcon/> <span v-text="user.walletCoins"></span>
            <TokenIcon/> <span v-text="user.walletToken"></span>
          </v-col>
        </v-row>
        <v-row v-if="user.progress">
          <v-col>
            <ProgressBarViewSmall :progress="user.progress"/>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
    <v-card>
      <v-card-title v-text="user.fullName"></v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content>Email: {{ user.email }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.telephone">
            <v-list-item-content>Telephone: {{ user.telephone }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.mobilephone">
            <v-list-item-content>Mobilephone: {{ user.mobilephone }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.birthday">
            <v-list-item-content>Birthday: {{ user.birthday }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Email Verified: {{ user.emailVerified }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Enabled: {{ user.enabled }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Privacy: {{ user.privacy }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Accepted Privacy: {{ user.acceptedPrivacy }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Total Raffles: {{ user.totalRaffles }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Registered At: {{ user.registeredAt }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.lastRaffle">
            <v-list-item-content>Last Raffle: {{ user.lastRaffle }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Object Slots: {{ user.objectSlots }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Commercial: {{ user.commercial }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Roles: {{ user.roles.join(', ') }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Identification Verified: {{ user.identificationVerified }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Wallet Coins: {{ user.walletCoins }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Wallet Token: {{ user.walletToken }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.bankDetails">
            <v-list-item-content>Bank Details: {{ user.bankDetails }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Total Points: {{ user.progress.totalPoints }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Level: {{ user.progress.level }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Points to Next Level: {{ user.progress.pointsToNextLevel }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Points in Current Level: {{ user.progress.pointsInCurrentLevel }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Current Level: {{ user.progress.currentLevel }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>