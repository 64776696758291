<template>


  <v-avatar :class="{'rounded':tile,'identified-successfully':identified}" color="secondary" :size="size">
    <v-skeleton-loader style="width:100%" v-if="loading"></v-skeleton-loader>

    <v-img v-else :src="user?.avatar ? 'data:image/webp;base64,'+user?.avatar:'https://ui-avatars.com/api/?name='+name+'&background=random'"></v-img>
    </v-avatar>

</template>

<script>
import {useAuthUserStore} from "@/stores/modules/auth.module";

export default {
  name: "UserAvatar",
  components:{
  },
  setup(){
    const authStore = useAuthUserStore();

    return{
      authStore
    }
  },
  computed:{
    name(){
      return this.user?.fullName || this.user?.email || this.user?.firstName+' '+this.user?.lastName || 'User';
    }
  },
  props:{
    identified:Boolean,
    tile:Boolean,
    user:Object,
    loading:Boolean,
    size:{
      default:40,
      type:Number
    }
  }
}
</script>

<style scoped>

</style>