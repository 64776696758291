// Pinia Store
import { defineStore } from 'pinia';
import {
    deleteAllNotifications,
    deleteNotification,
    getAllNotifications,
    notifyUser, readNotifications,
    sendDesktopPush
} from "@/services/notification.service";
import NotificationElephant from "@/models/notification";

const state = () => ({
        notifications:[]
});
export const useNotificationStore = defineStore('notificationStore',{
    state: state,



// getters
    getters: {
        getNewNotifications(){
            return this.notifications.filter(x => x.state === 'NEW')
        },
        getUnreadNotifications(){
            return this.notifications.filter(x => x.state != 'READ')
        },
        getAllRealNotifications(){
            return this.notifications.filter(x=>x.id!=null).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
    },

// actions
    actions:{
        async read(notification){
            const result= await readNotifications(notification);
            console.log(notification)
            console.log(this.notifications.findIndex(x => x.id ===notification.id));
            if(result) this.notifications[this.notifications.findIndex(x => x.id ===notification.id)]= result;
            else return null;
        },
        async delete(notification){
            const result= await deleteNotification(notification);
          if(result){
              this.notifications.splice(this.notifications.findIndex(x=>x.id===result),1);
                return true;
          }
          return false;
        },
        async deleteAll(){
          const result= deleteAllNotifications();
          if(result) this.notifications=[]
        },
        async getAllNotifications() {
            this.notifications = await getAllNotifications();
        },
        push(notification){
            sendDesktopPush(notification);
            this.notifications.push(notification);
        },
        pushText(text){
            sendDesktopPush(new NotificationElephant({message:text}))
            this.notifications.push(new NotificationElephant({message:text,color:'ERROR'}));
        },
        async notify(notification) {
            const response = await notifyUser(notification);
            if(response){
                this.push(response);
            }
            console.log(response)
        }

    }
});