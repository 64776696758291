<template>

  <v-tabs
      v-model="tab"
  >
    <v-tab value="pending">Pending</v-tab>
    <v-tab value="error">Error</v-tab>
    <v-tab value="done">Done</v-tab>
  </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="pending">
        <p v-if="!pending || pending.length<=0">Keine Auszahlungen wartend</p>
        <payout-item v-for="(payout,$index) of pending" :key="$index" :payout="payout"/>
      </v-window-item>

      <v-window-item value="error">
        <p v-if="!error || error.length<=0">Keine Auszahlungen mit Fehler</p>
        <payout-item v-for="(payout,$index) of error" :key="$index" :payout="payout"/>
      </v-window-item>

      <v-window-item value="done">
        <p v-if="!done || done.length<=0">Keine abgeschlossenen Auszahlungen</p>
        <payout-item v-for="(payout,$index) of done" :key="$index" :payout="payout"/>
      </v-window-item>
    </v-window>

</template>

<script>
import {getAllDonePayouts, getAllErrorPayouts, getAllPendingPayouts} from "@/services/admin.service";
import PayoutItem from "@/components/admin/payout/PayoutItem";

export default {
  name: "PayoutWrapper",
  components: {PayoutItem},
  data(){
    return{
      pending:null,
      error:null,
      done:null,
      tab:"pending"
    }
  },
  created() {
    this.getAllPendingPayouts();
    this.getAllErrorPayouts();
    this.getAllDonePayouts();
  },
  methods:{
    async getAllPendingPayouts() {
      this.pending = await getAllPendingPayouts();
    },
    async getAllErrorPayouts() {
      this.error = await getAllErrorPayouts();
    },
    async getAllDonePayouts() {
      this.done = await getAllDonePayouts();
    }
  }
}
</script>

<style scoped>

</style>