<template>
  <v-tooltip v-if="!serverState" text="Keine Verbindung zum Server" location="bottom">
    <template v-slot:activator="{props}">
      <v-btn @click="refresh" v-bind="props" color="error" icon="mdi-cloud-off"></v-btn>
    </template>

  </v-tooltip>
</template>
<script>
import {useStatusStore} from "@/stores/status.module";

export default {
  name: 'ServerStateIcon',
  setup() {
    const statusStore = useStatusStore();
    return {
      statusStore
    }
  },
  methods:{
    refresh(){
      location.href="/";
    }
  },
  computed: {
    serverState() {
      return this.statusStore.server;

    }
  },
}
</script>