<template>
  <div style="margin-top:-20px;" class=" mb-2 text-left">
    <small v-if="!courseOneCompleted">*um eine Immobilie anzulegen, muss unsere Datenschutz Weiterbildung abgeschlossen werden.</small>
  </div>
  <swiper
      :slidesPerView="'auto'"
      :spaceBetween="10"
      :freeMode="true"
      :modules="modules"
  >
    <swiper-slide style="width: 300px" v-for="object of myObjects" :key="object.id">
    <ObjectDisplaySmall :object="object"/></swiper-slide>
    <swiper-slide style="width:250px" v-for="slide of freeSlots" :key="slide+'free'">
      <v-card color="blue" style="border-radius: 20px;" class="pa-5 d-flex flex-wrap" width="80%" height="150px" elevation="0">
        <v-img cover style="position:absolute;top:0;left:0;width:100%;height:100%;opacity:0.1;z-index:0" :src="require('@/assets/'+image(slide))"></v-img>
        <div style="width: 100%;z-index:1" class="text-white text-left">Freier Slot</div>
        <div style="z-index:1" class="d-flex align-end justify-start">
          <div>
            <v-btn :disabled="!courseOneCompleted" variant="tonal" @click="$router.push('/immobilie/erstellen')" color="accent" block size="small">Immobilie anlegen</v-btn>
            <v-btn class="mt-2" small v-if="!courseOneCompleted" variant="tonal" @click="$router.push('/weiterbildung/sektion/1')" color="accent" block size="small">Zur Weiterbildung</v-btn>

          </div>
        </div>
      </v-card>
    </swiper-slide>
    <swiper-slide style="width:250px" :key="'locked'">
      <v-card style="border-radius: 20px;" class="pa-5 d-flex flex-wrap justify-space-between" color="blue" width="80%" height="150px" elevation="0">

        <div class="text-white text-left">Gesperrter Slot <v-icon size="x-small" icon="mdi-lock"></v-icon></div>
        <div class="d-flex align-end">
          <v-btn variant="tonal" color="success" block @click="buySlot" size="small">Slot freischalten</v-btn>
        </div>
      </v-card>
    </swiper-slide>

  </swiper>
  <v-dialog v-model="buyShow" max-width="500">
    <v-card>
      <v-card-title>Slot kaufen</v-card-title>
      <v-card-text>
       <p class="" v-if="!insufficientSells"> Kaufe einen neuen Slot, um weitere Immobilien hinzuzufügen.
       </p>
        <v-alert class="mb-2" v-if="insufficientSells">Du musst mindestens 2 Objekte verkauft haben, um neue Slots freizuschalten.</v-alert>
        <p><i>Ab der dritten Immobilie im Jahr wird von einer gewerblichen Tätigkeit ausgegangen. Bitte beachten Sie, dass Sie gegebenenfalls ein Gewerbe anmelden müssen.</i></p>
        <p>Der Preis erreichnet sich aus deinen zuletzt abgeschlossenen Tipps.</p>
        <p>Preis: <span v-if="!insufficientSells" class="text-accent">{{slotPrice}} Token</span><span v-else >-</span></p>
      </v-card-text>
      <v-card-actions>

        <v-btn @click="buyShow=false" variant="plain">Abbrechen</v-btn>
      <v-btn :disabled="insufficientSells" color="accent" @click="buySlotNow">Kaufen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--<v-slide-group v-model="active" center-active>
    <v-slide-group-item v-for="object of myObjects" :value="object.id" :key="object.id">
      <ObjectDisplaySmall :object="object" class="mx-2"/>
    </v-slide-group-item>
    <v-slide-group-item  v-for="slide of 2" :value="slide+'free'" :key="slide+'free'">
      <v-card color="blue" style="border-radius: 20px" class="pa-5 mr-2 d-flex flex-wrap" width="80vw" height="150px" elevation="2">
        <div style="width: 100%" class="text-white text-left">freier Slot</div>
        <div class="d-flex align-end justify-start">
          <v-btn variant="tonal" @click="$router.push('/immobilie/erstellen')" color="white" block size="small">Immobilie anlegen</v-btn>

        </div>
      </v-card>
    </v-slide-group-item>
    <v-slide-group-item v-for="slide in 1" :value="slide+'locked'" :key="slide+'locked'">
      <v-card class="pa-5  mr-2  d-flex flex-wrap justify-space-between" color="blue" width="80vw" height="150px" elevation="2">
        <div class="text-white text-left">gesperrter Slot <v-icon size="x-small" icon="mdi-lock"></v-icon></div>
        <div class="d-flex align-end">
          <v-btn variant="tonal" color="white" block size="small">Slot freischalten</v-btn>

        </div>
      </v-card>
    </v-slide-group-item>

  </v-slide-group>-->
</template>

<script>
import { defineComponent } from 'vue'
import ObjectDisplaySmall from "@/components/objects/ObjectDisplaySmall";
import {useObjectStore} from "@/stores/objects.module";
import {Swiper, SwiperSlide } from 'swiper/vue';
import{FreeMode} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import {useUserStore} from "@/stores/user.module";
import userService from "@/services/user.service";
export default defineComponent({
  name: 'ObjectCaroussel',
  components: {
    ObjectDisplaySmall,
    Swiper,
    SwiperSlide,
  },
  setup(){
    const objectStore = useObjectStore();
    const userStore = useUserStore();
    objectStore.getMyObjects();
    return{
      objectStore,
      userStore
    }
  },
  watch:{
    myObjects(){
      this.userStore.getMyFreeSlots();
    }
  },
  computed:{
    freeSlots(){
      return this.userStore.currentUser.freeSlots;
    },
    myObjects(){
      return this.objectStore.myObjects;
    }
  },
  created() {
    this.getSlotPrice();
    this.hasCourseOneCompleted();
  },
  methods: {
    async hasCourseOneCompleted() {
      let self=this;
      this.courseOneCompleted = await userService.hasCourseOneCompleted().catch(()=>{
        self.courseOneCompleted=false;
      });

    },
    async getSlotPrice() {
      let self=this;
      this.slotPrice = await userService.calculateSlotPrice().catch(()=>{
        self.slotPrice=16000;
        self.insufficientSells=true;
      });
    },
    image(index){
      index--;
      if(index>=this.images.length){
        if(index%2===0) return this.images[0];
        else return this.images[1];
      }
      return this.images[index];
    },
    buySlot(){
      this.buyShow = true;
    },
    buySlotNow(){
      if (confirm("Mit Klick auf OK werden dir "+this.slotPrice+" Token abgezogen & 1 Slot hinzugefügt") == true) {
        this.userStore.buySlotWithToken();
      } else {
        return null;
      }
    },
    onSwiper: function () {
      console.log("onSwiper called");
    },
    onSlideChange: function () {
      console.log("Slide change");
    },
  },
  data(){
    return{
      courseOneCompleted:false,
      insufficientSells:false,
      slotPrice:80000,
      buyShow:false,
      active:null,
      modules:[FreeMode],
      images:[
          "alexander-andrews-A3DPhhAL6Zg-unsplash.jpg",
          "brandon-griggs-wR11KBaB86U-unsplash.jpg",
          "etienne-beauregard-riverin-B0aCvAVSX8E-unsplash.jpg",
      ]
    }
  }
})
</script>
