<script>


import {nextTick} from "vue";

export default {
  name: "IntPhoneInput",
  data() {
    return {
      callingCountries: require('country-data').callingCountries.all,
      telPrefix: "+49",
      tel: "",
    }
  },
  props: {
    label:String,
    placeholder:String,
    disabled:Boolean,
    modelValue: String
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value === "" || value === null) {
          this.telPrefix = "+49";
          this.tel = "";
          return;
        }
        this.telPrefix = value.split(" ")[0];
        this.tel = value.split(" ")[1];
      },
      immediate: true
    }
  },
    computed: {
      fullNumber: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        }
      },
    },
    methods:{
      updateFullNumber() {
        nextTick(() => {
          if(this.tel==null) this.tel="";
          this.fullNumber = this.telPrefix + " " + this.tel.replace(" ","");
        });

      }
    },
    created() {
      let countryData = require('country-data').callingCountries.all;
      countryData.map((country) => {
        country.countryCallingCodes = country.countryCallingCodes[0];
      });
      this.callingCountries = countryData;
    }
}
</script>

<template>
  <div class="d-flex" style="gap:15px">
  <v-autocomplete  :disabled="disabled" class="hidden-arrow-select" @update:modelValue="updateFullNumber" v-model="telPrefix" style="width:100px" v-if="callingCountries" item-name="countryCallingCodes" item-title="countryCallingCodes" item-value="countryCallingCodes" :items="callingCountries">

  <template #item="{props,item}">
    <v-list-item-title class="pa-1" v-bind="props">
      <div>
          <span>{{item.raw.emoji}}</span>
        <small class="ml-1">({{item.raw.countryCallingCodes}})
        </small>
      </div>
    </v-list-item-title>
  </template>
</v-autocomplete>
  <v-text-field :label="label" :placeholder="placeholder" :disabled="disabled" style="width:100%" @change="updateFullNumber" v-model="tel"/>
  </div>
</template>

<style scoped>

</style>