<template>
<v-slide-group class="my-2"
    selected-class="bg-success"
>
  <v-slide-group-item>
  <v-sheet style="position: relative" class="v-ripple d-flex justify-center align-center" rounded width="150px" height="100px" color="secondary">
    <v-icon icon="mdi-camera" large></v-icon>
    <input :disabled="disabledP" type="file"
           multiple
           class="file-input"
           style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
           @change="addImages"
           accept="image/png, image/jpeg,image/bmp"/>
  </v-sheet>
  </v-slide-group-item>
  <v-slide-group-item v-for="(image,$index) of imagesReactive" :key="$index">
    <div class="mx-2" style="width:150px;height:100px;">
    <v-img :alt="image.filename" width="100%" height="100%"  :src="'data:image/webp;base64,'+image.file"></v-img>
    </div>
  </v-slide-group-item>

</v-slide-group>
</template>

<script>
import File from "@/models/file";
import {compressImage} from "@/services/image.service";

export default {
  name: "AddImages",
  props:{
    images:Array,
    disabledP:Boolean
  },
  computed:{
    imagesReactive:{
      get(){
        return this.images
      },
      set(value){
        this.$emit("updateImages",value);
      }
    }
  },
  methods:{
    async convertBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {

          let base64String=fileReader.result;
          base64String=base64String.replace("data:","");
          base64String=base64String.split(";")[1];
          base64String=base64String.split(",")[1];
          resolve(base64String);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },
    async addImages(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      for (let file of files) {
        console.log(this.imagesReactive)
        //TODO Compress File
        file=await compressImage(file)
      this.imagesReactive.push(new File(file.name, file.type,file.size, await this.convertBase64(file)));
      }

    }
  }
}
</script>

<style scoped>

</style>