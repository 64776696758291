export default class IdentificationDocument{
    userId;
    docType;
    docNumber;
    docCountry;
    fullName;
    validUntil;
    version;
    birthDate;
    checkSum;
    constructor(userId=null,docType="GERMAN_IDENTITY_CARD",docNumber=null,docCountry="Deutschland",fullName=null,validUntil=null,birthDate=null,checkSum=null,version=null) {
        this.userId = userId;
        this.docType = docType;
        this.docNumber = docNumber;
        this.docCountry = docCountry;
        this.fullName = fullName;
        this.validUntil = validUntil;
        this.birthDate = birthDate;
        this.checkSum = checkSum;
        this.version=version;

    }
}