// Pinia Store
import { defineStore } from 'pinia';
import {canUserRaffle, getMyRewards} from "@/services/reward.service";

const state = () => ({
    rewards:[],
    claimedRewards:[],
    canRaffle:false
});
export const useRewardStore = defineStore('userRewards',{
    state: state,



// getters
    getters: {

    },

// actions
    actions:{
        async canUserRaffle(){
            const response = await canUserRaffle();
            this.canRaffle = response;
        },
        async getMyRewards(){
          const response = await getMyRewards();
          this.rewards=response;
        },
    }
});